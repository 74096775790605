.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu h2 {
  font-size: 32px;
  line-height: 45px;
  font-weight: 400;
  color: black;
  padding: 10px 0px;
}
.accordion-header {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 0px !important;
}
.introdu h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.introdu ul li::marker {
  color: var(--primary);
}
.nav-tabs .nav-link.active {
  background: #fff !important;
  display: block;
  color: var(--primary) !important;
}
.cart-2 .nav-tabs .nav-item.show .nav-link,
.cart-2 .nav-tabs .nav-link.active {
  border: none;
  background-color: transparent;
  border-color: transparent;
}
.cart-2 .nav-tabs .nav-link:focus,
.cart-2 .nav-tabs .nav-link:hover,
.cart-2 .nav-tabs .nav-link:focus-visible {
  /* isolation: isolate; */
  border: none;
  outline: none;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.cart-1.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent;
}
button#nav-home-tab12 {
  padding: 27px 0px;
  background-color: #efefef;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
}
button#nav-home-tab12:focus {
  background-color: #ffff;
  color: var(--primary);
}
button#nav-profile-tab22 {
  padding: 27px 0px;
  background-color: #efefef;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
}
button#nav-profile-tab22:focus {
  background-color: #ffff;

  color: var(--primary);
}
button#nav-contact-tab {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
  padding: 27px 0px;
  background-color: #efefef;
}
button#nav-contact-tab:focus {
  background-color: #ffff;
  color: var(--primary);
}

table.customTable {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 3px;
  border-color: #ffffff;
  border-style: solid;
  color: #000000;
}

table.customTable td,
table.customTable th {
  border-width: 1px;
  border-color: #ddd;
  border-style: solid;
  padding: 20px 20px;
  width: 50%;
  text-align: center;
}

table.customTable thead {
  background-color: var(--primary);
}
.intro .container-xxl {
  overflow-x: hidden;
}
.human .container-xxl {
  overflow-x: hidden;
}
