.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.timeline_profile {
  height: 150px;
}
.App-link {
  color: #61dafb;
}
.over-empl.overflowww {
  overflow-y: scroll;
  height: 300px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.view_status {
  border: none;
  padding: 8px 10px;
  background-color: green;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.nav-link-main {
  height: 564px;
  padding-bottom: 87px;
  overflow: auto;
}

button.text-danger.deletebtn {
  border: none;
  font-size: 20px;
  background: white;
}
.banner-ach {
  background-image: url(assets/banner/images/banner_bg.7a1fdab43264cde0c4ee.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

/*****2025-03-25*****/

.jlhome {
  background-color: #2b3767;
}
.jlhome .jlhome_box {
  background-color: #fff;
  padding: 25px;
  overflow: hidden;
  height: 406px;
}
.jlhome .jlhome_box h3 {
  font-size: 25px;
  font-weight: 600;
  color: #000;
  text-align: center;
}
.jlhome .jlhome_box p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.jlhome .jlhome_box .jl_home_img {
  height: 150px;
}

.notification_center h3 {
  font-size: 35px;
  font-weight: 500;
  color: #000;
}
.notification_center p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.notification_center button {
  background-color: transparent;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.368627451);
  margin: 0 auto;
}
.notification_center button h4 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4666666667);
}
.notification_center button h3 {
  font-size: 25px;
  font-weight: 600;
  color: #000;
  text-decoration: underline;
}

.border_home {
  display: inline-block;
  position: relative;
  background: none;
  width: 100%;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.09);
  overflow: hidden;
  border-radius: 20px;
  transition: 0.3s all ease-in-out;
  &:hover {
    transform: translateY(0) rotateX(4deg) rotateY(15deg);
    box-shadow: 0 10px 20px rgb(255 255 255 / 20%);
    transition: 0.3s all ease-in-out;
  }
}

/* .border_home::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 4px;
  background: linear-gradient(
    90deg,
    rgb(2, 0, 36) 0%,
    rgb(9, 9, 121) 35%,
    rgb(0, 212, 255) 100%
  );
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
}

.border_home:hover::after {
  width: 100%;
  background: linear-gradient(
    90deg,
    rgb(2, 0, 36) 0%,
    rgb(9, 9, 121) 35%,
    rgb(0, 212, 255) 100%
  );
}

.border_home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 4px;
  background: linear-gradient(
    90deg,
    rgb(2, 0, 36) 0%,
    rgb(9, 9, 121) 35%,
    rgb(0, 212, 255) 100%
  );
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.6s;
}

.border_home:hover::before {
  width: 100%;
}

.border_home a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 4px;
  height: 0%;
  background: linear-gradient(
    0deg,
    rgb(2, 0, 36) 0%,
    rgb(9, 9, 121) 35%,
    rgb(0, 212, 255) 100%
  );
  border-radius: 10px;
  transition: all 0.9s ease-in-out;
}

.border_home a:hover::after {
  height: 100%;
}

.border_home a::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 0%;
  background: linear-gradient(
    0deg,
    rgb(2, 0, 36) 0%,
    rgb(9, 9, 121) 35%,
    rgb(0, 212, 255) 100%
  );
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.3s;
}

.border_home a:hover::before {
  height: 100%;
} */

.close_nav {
  display: none;
}
@media only screen and (max-width: 991.98px) {
  .close_nav {
    display: block;
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 9898;
    background-color: transparent;
    border: none;
  }
}

.h-100vh {
  height: 100vh;
}

.main_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.main_loader .loader {
  width: 25px;
  aspect-ratio: 1;
  display: grid;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  border-right-color: #fff;
  animation: l15 1s infinite linear;
}
.main_loader .loader::before,
.main_loader .loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.main_loader .loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15 {
  100% {
    transform: rotate(1turn);
  }
}

.preview-container {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 200px;
  background: #000;
  padding: 15px;
  border-radius: 10px;
}
.preview-container button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px !important;
  height: 25px !important;
  font-size: 10px;
  background: #fff !important;
}

.word {
  word-wrap: break-word;
}

/*****2025-03-25*****/
i.bi.bi-search.search-icon.searchhomenew {
  top: 14px;
  left: 16px;
}
button#basic-addon2 {
  background: #000;
  padding: 18px;
}
.searchhomenew:before {
  color: white !important;
  font-size: 14px;
  font-weight: 900 !important;
}

svg.fontsize {
  color: green;
  font-size: 25px;
  font-weight: 900;
}

.slick-dots {
  bottom: 15px; /* Adjust the position */
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: white !important; /* White color */
  opacity: 0.5; /* Make inactive dots slightly visible */
  transition: opacity 0.3s ease-in-out;
}

.slick-dots li.slick-active button:before {
  opacity: 1; /* Fully visible active dot */
  color: white !important; /* White color for active dot */
}
