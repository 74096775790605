.por-tesk {
  border: 2px solid #e4e6ea;
  border-radius: 6px;
}
.por-tesk .title-box {
  background: #fff;
}
.por-tesk .title-box h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}
.por-tesk .title-box h6 {
  font-size: 16px;
  font-weight: 300;
  color: #333;
}

.probar {
  background: #eaf6fd;
  padding: 0px 0 40px 0;
}
.probar > div {
  margin-top: -30px;
}
.overdue {
  background: #eaf6fd;
}
.overdue h4 {
  color: var(--primary);
  font-size: 20px;
}
.overdue .fa-exclamation-triangle {
  color: var(--primary);
}
.overdue h6 {
  font-size: 14px;
  font-weight: 300;
}
.overdue h5 {
  color: var(--secondary);
  font-size: 18px;
}
.fa-clock {
  color: var(--secondary);
}
.inci-tab li .nav-link {
  border-top: none;
  border-right: none;
  border-left: none;
  background: #f3f3f3;
  border-bottom: 2px solid transparent;
  color: #333;
  font-size: 14px;
  border-radius: 0px;
}
.inci-tab li .nav-link.active {
  border-bottom: 2px solid var(--primary);
  color: var(--primary);
}
.inci-tab li .nav-link:hover {
  border-bottom: 2px solid var(--secondary);
  color: var(--secondary);
}
.number-siz-title h6 {
  font-size: 14px;
  color: #333;
  font-weight: 400;
}
.number-siz-title h6 a {
  color: #2457ff;
  margin-left: 15px;
}
.cbtn {
  background: var(--primary);
  color: #fff;
  font-size: 14px;
  display: inline-block;
  border-radius: 6px;
  padding: 10px 30px;
}

.circle-big {
  position: relative;
  width: 114px;
  height: 114px;
  margin: 30px auto 25px auto;
}

.circle-big svg {
  width: 114px;
  height: 114px;
}

.circle-big .bg {
  fill: none;
  stroke-width: 10px;
  stroke: #9e9e9e;
}

.circle-big .progress {
  fill: none;
  stroke-width: 10px;
  stroke: #274fff;
  stroke-linecap: round;
  stroke-dasharray: 326.56;
  stroke-dashoffset: 60;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  animation: big 1.5s ease-in-out;
}

.circle-big .text {
  position: absolute;
  width: 100%;
  top: 37px;
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  line-height: 22px;
}

.circle-big .text .small {
  font-size: 14px;
  color: #fff;
}

.circles-small {
  margin: 0 auto;
  width: 400px;
  height: 80px;
  text-align: center;
}

.circle-small {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0px;
}

.circle-small svg {
  width: 80px;
  height: 80px;
  transform: scale(0.5);
}

.circle-small .bg {
  fill: none;
  stroke-width: 6px;
  stroke: #9e9e9e;
}

.circle-small .progress {
  fill: none;
  stroke-width: 6px;
  stroke: #274fff;
  stroke-linecap: round;
  stroke-dasharray: 232.36;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.circle-small .progress.one {
  stroke-dashoffset: 80;
  animation: one 2.5s ease-in-out, appear 1s;
}

.circle-small .progress.two {
  stroke-dashoffset: 140;
  /* animation: two 3.5s ease-in-out, appear 2s; */
}

.circle-small .text {
  position: absolute;
  width: 100%;
  top: 31px;
  font-size: 17px;
  text-align: center;
  font-weight: 400;
  line-height: 16px;
}

.circle-small .text .small {
  font-size: 12px;
  color: #fff;
}

@keyframes big {
  from {
    stroke-dashoffset: 326.56;
  }
  to {
    stroke-dashoffset: 60;
  }
}

@keyframes one {
  from {
    stroke-dashoffset: 232.36;
  }
  to {
    stroke-dashoffset: 80;
  }
}

@keyframes two {
  from {
    stroke-dashoffset: 232.36;
  }
  to {
    stroke-dashoffset: 140;
  }
}

@keyframes appear {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.slider-block .slick-track > div {
  padding: 10px;
}
.slider-post {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
  color: #000;
  height: 150px;
}
.slider-post h5 {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.slider-post h6 {
  font-size: 13px;
  color: #000;
  font-weight: 400;
}
.elearn-title h3 {
  color: #333;
  font-size: 26px;
  font-weight: 600;
}
.elearn-title a {
  font-size: 18px;
  font-weight: 300;
}
.course-block {
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #ccc;
}
.course-block .course-img {
  height: 280px;
}
.course-block .course-img .fal {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
  font-size: 20px;
  color: #fff;
}
.course-title-box h3 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
.course-title-box p {
  color: #666666;
  font-size: 16px;
}
.cour-btn {
  background: #fff;
  border: 2px solid var(--primary);
  color: var(--primary);
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 6px;
  display: inline-block;
}
.cour-btn:hover {
  background: var(--primary);
  color: #fff;
}
.duration-block {
  background: #f0f6fa;
  color: #111;
}
.duration-block h4 {
  color: #000;
  font-size: 16px;
  font-weight: 300;
}
.hazard-title h3 {
  font-size: 26px;
  color: #333;
  font-weight: 600;
}
.attach-doc {
  background: #fff;
  border: 2px solid var(--primary);
  color: var(--primary);
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 6px;
  display: inline-block;
  position: relative;
  z-index: 40;
}
.attach-doc:hover {
  background: var(--primary);
  color: #fff;
}
.hazard-form-block label {
  font-size: 14px;
  color: #111;
}
.hazard-form-block input[type="text"],
.hazard-form-block input[type="email"],
.hazard-form-block input[type="password"],
.hazard-form-block input[type="tel"],
select,
textarea {
  border: 1px solid #a1a9b3;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  width: 100%;
}
.buttons-two button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
}
.buttons-two .cel-btn {
  color: var(--primary);
  background: #fff;
}
.buttons-two .rep-btn {
  color: #fff;
  background: var(--primary);
}
.need-block p {
  font-size: 16px;
  color: #000;
}
.need-block p a {
  color: var(--primary);
}
.doco-block .plus-btn {
  color: var(--primary);
  border: none;
  background: none;
}
.doco-block .doco-title {
  color: #111;
  font-size: 16px;
  font-weight: 600;
}
.icon-empty .img-block {
  width: 80px;
}
.icon-empty .img-content {
  font-size: 14px;
  color: #000;
}
.attach-title-pop {
  background: var(--primary);
}
.attach-title-pop h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
}
.attach-doc-pop {
  border: 2px dashed #ccc;
}
.attach-doc-pop input[type="file"] {
  position: absolute;
  border: 1px solid red;
  inset: 0;
  width: 100%;
  opacity: 0;
  z-index: 33;
  cursor: pointer;
}
.drag-content {
  position: relative;
  z-index: 10;
}
.drag-content h5 {
  color: #999;
  font-size: 24px;
}
.drag-content h6 {
  color: #000;
  font-size: 16px;
  font-weight: 300;
}
.drag-content h6 span {
  color: var(--primary);
}
.brow-nav {
  border-radius: 6px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.brow-nav li {
  width: 33.3%;
  padding: 8px 20px;
  background: #ebf7ff;
  position: relative;
  text-align: center;
}
.brow-nav li:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #ebf7ff;
  border-width: 20px;
  margin-top: -20px;
  z-index: 2;
}
.brow-nav li:after {
  left: 102.9%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #fff;
  border-width: 17px;
  margin-top: -17px;
  z-index: 1;
}
.brow-nav li h3 {
  font-size: 16px;
  color: #999;
  font-weight: 400;
  text-align: center;
}
.brow-nav .active {
  background: var(--primary);
  color: #fff;
}
.brow-nav .active h3 {
  color: #fff;
}
.brow-nav .active:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: var(--primary);
  border-width: 17px;
  margin-top: -17px;
  z-index: 2;
}

.form-check-label {
  cursor: pointer;
}
.search-icon {
  position: relative;
}
.search-icon:before {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  content: "\f002";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  color: #000;
  bottom: 3px;
  right: 5px;
  position: absolute;
  z-index: 0;
}
.h-title {
  color: #000;
  font-size: 26px;
  font-weight: 600;
}
.btn-wintess {
  border: 2px solid var(--secondary);
  color: var(--secondary);
  font-size: 16px;
  padding: 4px 20px;
  font-size: 14px;
  background: #ffff;
  text-transform: capitalize;
  border-radius: 6px;
}
.name-form .form-check label{
  padding-top: 7px;
}
.form-check{
  display: flex !important;
  align-items:center;
}
@media (max-width: 767px) {
  .brow-nav {
    flex-wrap: wrap;
  }
  .brow-nav li {
    width: 49%;
  }
  .brow-nav li h3 {
    font-size: 12px;
  }
}
.notif-content h3{
  font-size: 26px;
  font-weight: 600;
  color: #111;
}
.notif-content p{
  color: #111;
  font-size: 16px;
}
.report-box{
  box-shadow: 2px 3px 3px rgba(0,0,0,0.1);
  border-radius: 10px;
  padding: 15px 10px;
  border: 1px solid #ebebeb;
}
.report-box:hover{
  box-shadow: 3px 4px 3px rgba(0,0,0,0.1);
}
.report-box h2{
  font-size: 18px;
  font-weight: 600;
  color: #111;
}
.report-box:hover h2{
text-decoration: underline;
}
.report-box p{
  font-size: 14px;
  font-weight: 1300;
  color: #000;
}
.brow-nav .active a{
  background: var(--primary);
  color: #fff;
}
.slider-block .slick-arrow {
  background: #b3b3b3 !important;
  
}
.report-summary h3{
  font-size: 30px;
  color: #222;
  font-weight: 600;
  line-height: 30px;
}
.report-summary h4{
  font-size: 26px;
  color: #999;
  font-weight: 600;
  line-height: 30px;
}
.report-summary .back-btn{
  color: var(--primary);
  font-size: 14px;
  display: inline-block;
}
.report-summary .ful-btn{
  background: var(--primary);
  color: var(--white);
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 14px;
  display: inline-block;
}
.inperson h3{
  font-size: 30px;
  color: #222;
  font-weight: 600;
  line-height: 30px;
}
.inperson label{
  font-size: 16px;
  font-weight: 600;
  color: var(-black);
}
.inperson h6{
  font-size: 14px;
  font-weight: 300;
}
.list-view-report .far{
  color: #000;
  font-size: 30px;
}
.list-view-report .file-name{
  font-size: 14px;
}
.list-view-report .file-date{
  font-size: 14px;
  color: #999;
}
.delete-btn{
  border: none;
  background: #fff;
}
.doco-notes li button{
  font-size: 14px;
}
.note-title-tag h2{
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.note-title-tag select{
  font-size: 16px;
  background: #fff;
  border: none;
  padding: 0;
}
.user-name-notes h3{
font-size: 18px;
color: #000;

}
.user-name-notes p{
font-size: 14px;
color: #999;

}
.text-enter{
border: 1px solid #ccc;
padding: 10px;
}
.text-enter h4{
font-size: 14px;
color: #000;
font-weight: 300;
}
.new-note .user-avtar{
width: 30px;
height: 30px;
display: grid;
background-color: rgb(26, 118, 255);
place-items: center; 
color: #fff;
padding: 6px;
border-radius: 100px;
overflow: hidden;
}
.new-note .user-avtar h3{
font-size: 16px;
}
.new-note textarea{
width: 100%;
font-size: 14px;
color: #000;
}
.save-note-btn button{
border: 2px solid rgb(26, 118, 255);
color: rgb(26, 118, 255);
font-size: 14px;
padding: 6px 10px;
background: #fff;
}
.char-text{
font-size: 12px;
}
.report-box p{
  font-size: 14px;
  font-weight: 1300;
  color: #000;
}

.slider-block .slick-arrow {
  background: #b3b3b3 !important;
  
}
.file-pop .top-head {
        background-color: var(--primary);
        color: #fff;
        padding: 10px;
      }
      .file-pop .top-head h2{
        font-size: 20px;
        font-weight: 600;
      }
      .file-pop .close-btn{
        background: #fff;
        color: #000;
        border: none;
        border-radius: 100px;
        width: 30px;
        aspect-ratio: 1;
        height: 30px;
      }
      .file-pop .choose-text{
        font-size: 14px;
        font-weight: 300;
        padding-bottom: 10px;
      }
      .file-pop .sceenshot-box h4{
        color: #000;
        font-size: 16px;
      }
      .file-pop .scsbtn{
        border: none;
        background: none;
      }
      .file-pop .drop-box-pop{
        border: 2px dashed #999;
        width: 97%;
        margin:  10px 0;
      }
      .drop-box-pop i{
        font-size: 30px;
        color: #999;
      }
      .drop-box-pop h1{
        color: #111;
        font-size: 26px;
        font-weight: 600;
      }
      .drop-box-pop h6{
        color: var(--primary);
        font-size: 16px;
        border: 1px solid var(--primary);
        width: 200px;
        padding: 6px 0;
        margin: 0 auto;
        font-weight: 400;
      }
      .drop-box-pop .file-name-block{
        position: absolute;
        inset: 0;
        width: 100%;
        z-index: 44;
        cursor: pointer;
        opacity: 0;
      }
      .drag-box label{
        font-size: 16px;
        color: #000;
      }
      .drag-box select{
        background: #fff;
        font-size: 16px;
        padding: 10px;
      }
      .drag-box .check-box-list h6{
        font-size: 16px;
        color: #000;
        font-weight: 400;
      }
      .drag-box .btns-box .flex-shrink-0:first-child button{
        background: var(--primary);
        border: none;
        color: #fff;
        padding: 6px 30px;
      }
      .drag-box .btns-box .flex-shrink-0:last-child button{
        border: 1px solid var(--primary);
        color: var(--primary);
        background: #fff;
        padding: 6px 30px;
      }
      .ReactModal__Content.ReactModal__Content--after-open.docs.d-flex.flex-column.bg-light.mt-5.p-3.align-item-center.justify-content-center {
        max-width: 533px;
    }
    button.buttonopcity {
      opacity: 0.1;
  }

  .row.editopt {
    padding: 20px;
    border: 1px solid #cd1929;
    border-radius: 12px;
    margin-top: 10px;
}

/***New Css Start***/

.open .six{
  width: 42px;
  height: 26px;
  border-radius:61px;
  background-color:#2857AD ;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
#mid-open .test{
  padding: 10px;
  border-radius: 5px;
  border: 1px solid  #dbd6d6;
}
#mid-open .pop-up  button.btn.dropdown-toggle{
 display: flex;
}
#mid-open .pop-up ul.dropdown-menu.show.dropdown-menu-end.p-0 {
  position: absolute;
  top: 50px;
  right: 50px;
}
.sitesdocs.css-b62m3t-container {
  pointer-events: none;
  z-index: 999;
}
.assitesdocs {
  pointer-events: none;
  opacity: 0.1;
}
.scur{
  border-radius: 6px;
  width: 30px;
  aspect-ratio: 1;
  overflow: hidden;
  height: 30px;
  border: 1px solid #cccccc;
}
.stext-block h6{
  font-size: 13px;
  color: #999;
  font-weight: 400;
}
.title-heading-box h2{
  color: #000;
  font-size: 16px;
}
.title-heading-box p{
  color: #999;
  font-size: 14px;
}
.title-heading-box p span{
  color: #000;
}
.ft-heading p{
  font-size: 12px;
  color: #999;
}
.cur-block{
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  height: 400px;
}
.cur-block .cur-img{
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}
button.sites_docs {
  opacity: 0.1;
  pointer-events: none;
}
.check {
  position: absolute;
  top: 4px;
  left: -21px;
}
.check1 {
  position: absolute;
  top: 4px;
  left: -19px;
}
.check i{
  font-size: 10px;
}
.check1 i{
  font-size: 10px;
}
.text-fail{
  color: red;
}
label.form-check-label.rabutton {
  margin-left: 5px;
}
/***New Css End***/