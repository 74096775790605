@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");
input,
textarea,
select,
ol,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-decoration: none;
  outline: none;
  list-style: none;
  padding: 0;
}

a,
a:hover,
a:focus,
*:focus-visible {
  cursor: pointer;
  transition: all 0.4s ease 0s;
  text-decoration: none;
  outline: none;
}

button:focus {
  outline: none;
  border: none;
}

button:focus,
input:focus {
  box-shadow: none !important;
}

button {
  cursor: pointer;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
  font-family: "Josefin Sans", sans-serif;
}

header {
  border-bottom: 1px solid rgba(0, 28, 113, 0.4392156863);
  background-color: #ffffff;
  padding: 10px 0px;
}
header nav .logo-top {
  width: 180px;
  display: block;
}
header nav .navbar-list {
  position: relative;
}
header nav .navbar-list .nav-dot {
  position: relative;
  z-index: 9;
}
header nav .navbar-list .nav-dot i {
  font-size: 22px;
  color: #000;
}
header nav .navbar-list ul {
  position: absolute;
  background: rgb(242, 241, 241);
  top: -10px;
  right: -10px;
  z-index: 1;
  display: none;
}
header nav .navbar-list ul li {
  display: block;
}
header nav .navbar-list ul li a {
  display: block;
  color: #333c8b;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid #c0c0c0;
  padding: 15px 80px;
}
header nav .navbar-list ul li:hover {
  background-color: #d5d4d4;
}

.banner {
  position: relative;
  background-image: url(./images/banner.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 120vh;
}
.banner::before {
  content: "";
  position: absolute;
  background-image: linear-gradient(250deg, #001c71 0%, #0082ca 94%);
  opacity: 0.61;
  top: 0;
  width: 100%;
  height: 100%;
}
.banner .bottom-shape {
  position: absolute;
  z-index: 15;
  bottom: -2px;
  width: 100%;
}
.banner .bottom-shape svg {
  fill: #fff;
}
.banner .banner-text {
  width: 80%;
  margin: 0 auto;
}
.banner .banner-text h2 {
  font-size: 35px;
  font-weight: 600;
  color: #fff;
}
.banner .banner-text p {
  font-size: 30px;
  font-weight: 300;
  color: #fff;
}
.banner .banner-text h6 {
  font-size: 25px;
  font-weight: 300;
  color: #fff;
  text-align: center;
}
.banner .banner-text h4 {
  font-size: 35px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.items {
  margin-top: -171px;
  z-index: 19;
  position: relative;
}
.items .box_l {
  width: 30%;
}
.items .box_mid {
  width: 40%;
  height: 400px;
}
.items .box_mid p {
  font-size: 18px !important;
}
.items .box_mid .item_mid-fit {
  width: 80px;
}
.items .box1 {
  padding: 30px 50px;
  background-color: #fffdfd;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #d8d8d8;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.items .box1 .item-fit {
  width: 50px;
}
.items .box1 a {
  width: 100%;
  text-align: center;
  padding: 10px 30px;
  border: 1px solid #001c71;
  color: #001c71;
  font-weight: 800;
}
.items .box1 h5 {
  font-size: 22px;
  font-weight: 900;
  color: #000;
}
.items .box1 p {
  font-size: 15px;
}

.logo-slider .logo-img {
  width: -moz-fit-content;
  width: fit-content;
  height: 150px;
}
.logo-slider .slick-track {
  display: flex;
  gap: 70px;
  justify-content: center;
  align-items: center;
}
.logo-slider .logo-img-h {
  height: 100px;
}

@media (max-width: 1200px) {
  .items {
    margin-top: -101px;
    z-index: 19;
    position: relative;
  }
}
@media (max-width: 1000px) {
  .items {
    margin-top: 0px;
    z-index: 19;
    position: relative;
  }
  .box_l {
    width: 100% !important;
  }
  .box_mid {
    width: 100% !important;
    height: -moz-fit-content;
    height: fit-content;
  }
  .box_mid p {
    font-size: 18px !important;
  }
  .box_mid .item_mid-fit {
    width: 80px;
  }
}
@media (max-width: 680px) {
  .banner .banner-text h2 {
    font-size: 28px;
    font-weight: 600;
    color: #fff;
  }
  .banner .banner-text p {
    font-size: 22px;
    font-weight: 300;
    color: #fff;
  }
  .banner .banner-text h6 {
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    text-align: center;
  }
  .banner .banner-text h4 {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
}

.form {
  width: 959px;
  margin: 0 auto;
}
.form .nav-tabs {
  border-bottom: 2px solid rgba(8, 8, 8, 0.507);
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
}
.form .nav-tabs .nav-item button {
  color: #020202;
}
.form .nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid rgb(0, 0, 0);
  width: -moz-fit-content;
  width: fit-content;
  color: #000 !important;
}
.form .nav-tabs .nav-item .nav-link {
  border: none;
  display: flex;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  align-items: center;
  gap: 2px;
  width: fit-content;
}
.form .tab-pane .selsct_roster {
  font-size: 22px !important;
  font-weight: 900;
}
.form .tab-pane input {
  padding: 7px 7px;
}
.form .tab-pane textarea {
  padding: 3px 7px !important;
}
.form .tab-pane select {
  padding: 7px 7px !important;
}
.form .tab-pane label span {
  color: red;
}
.form .tab-pane .photo-drag {
  line-height: 15px;
}
.form .tab-pane .photo-drag i {
  font-size: 14px;
}
.form .tab-pane .chose-file {
  padding: 5px 30px;
  cursor: pointer;
  opacity: 0;
  width: 275px;
  z-index: 9;
}
.form .tab-pane .chose-btn {
  background-color: white;
  border: 1px solid #3f3f3f;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4392156863);
  position: absolute;
  padding: 5px 30px;
}
.form .tab-pane h3 {
  font-size: 22px;
}
.form .tab-pane h4 {
  font-size: 35px;
  color: #001c71;
  font-weight: 800;
}
.form .tab-pane p {
  font-size: 20px;
  color: #000;
}
.form .tab-pane ul li {
  list-style-type: disc;
}
.form .tab-pane h5 {
  font-size: 30px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}
.form .tab-pane .home-main input[type="radio"] {
  color: red;
}
.form .tab-pane .home-main label {
  font-size: 20px;
}
.form .tab-pane .cl-red h5 {
  font-size: 30px;
  font-weight: 600;
  color: red;
}
.form .tab-pane .cl-red p {
  color: red;
}
.form .tab-pane .cl-red ul li::marker {
  color: red;
}
.form .tab-pane .cl-red ul li p {
  color: red;
}
.form .tab-pane .save-btn {
  border: 1px solid rgb(0, 0, 0);
  padding: 10px 30px;
}
.form .tab-pane .next-btn {
  background-color: #73d240;
  border-radius: 3px;
  border: 1px solid transparent;
  padding: 10px 30px;
  color: #fff;
}
.form .tab-pane .add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form .tab-pane .add-btn button {
  background-color: #001c71;
  border-radius: 3px;
  border: 1px solid transparent;
  padding: 6px 30px;
  color: #fff;
}
.form .tab-pane .dark-text {
  font-size: 15px;
  color: #706f6f;
}

@media (max-width: 959px) {
  .form {
    width: 100%;
    padding: 20px !important;
  }
  .form .nav-item {
    width: 15% !important;
  }
  .form .nav-link {
    display: flex;
    width: 100% !important;
    overflow: hidden;
  }
} /*# sourceMappingURL=main.css.map */

.jl-loader h4 {
  font-size: 30px;
  font-weight: 600;
  color: #001c71;
}
.jl-loader .loader-line {
  height: 7px;
  width: 400px;
  border-radius: 5px;
  background-color: #616161;
}
.jl-loader .loader-line .inner-line {
  height: 7px;
  border-radius: 5px;
  background-color: #c5c5c5;
  animation: loader-line 3s infinite;
}
.jl-loader .spinner-border {
  margin-top: -30px;
  width: 75px;
  height: 75px;
  --bs-spinner-border-width: 0.4em;
}
span.text-white.bgred {
  background: red;
}
@keyframes loader-line {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.save-later_mail {
  width: 555px;
  margin: 0 auto;
}
.save-later_mail button {
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 20px;
  border: none;
  background-color: #4c4c4c;
}
.save-later_mail button .copy_link {
  width: 18px;
}
.save-later_mail button p {
  color: #ddd;
  font-size: 14px;
}
.save-later_mail h5 {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}
.save-later_mail p {
  font-size: 18px;
  color: #606060;
}
.save-later_mail .send_mail-btn input {
  width: 350px;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.save-later_mail .send_mail-btn button {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  font-weight: 600;
  background: #068ff6;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 600px) {
  .save-later_mail {
    width: 100%;
    margin: 0 auto;
  }
  .save-later_mail h5 {
    font-size: 30px;
    font-weight: 600;
    color: #000;
  }
  .save-later_mail p {
    font-size: 18px;
    color: #606060;
  }
  .save-later_mail .send_mail-btn input {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
  .save-later_mail .send_mail-btn button {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    font-weight: 600;
    background: #068ff6;
    color: #fff;
    cursor: pointer;
  }
}

.profile-form label {
  padding-bottom: 5px;
  text-align: start;
}
.profile-form input {
  padding: 4px;
}
.profile-btns .save-btn {
  padding: 10px 30px;
  border: none !important;
  background-color: gray;
  color: #fff;
}
.profile-btns .next-btn {
  padding: 10px 30px;
  border: none !important;
  background-color: red;
  color: #fff;
}

.profile_mainyh .inperson ul li button {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #000 !important;
}

.profile_mainyh .inperson ul li button.active {
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 2px solid red;
}
.profile_mainyh .inperson ul li button p {
  display: flex;
  gap: 3px;
}

.profile_mainyh .inperson ul li button.active p {
  display: flex;
  gap: 3px;
  color: red;
}

.profile-chosie button.chose-btn {
  position: absolute;
  z-index: 91;
  top: 3px;
  font-size: 12px;
  padding: 4px 20px;
}
.profile-chosie input.chose-file {
  opacity: 0;
  z-index: 999;
}
