body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.timesheet_table thead {
  position: sticky;
  height: 100%;
  width: 100%;
  top: 0px;
}
.timesheet_table .styled-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}
.timesheet_table .styled-table th,
.timesheet_table .styled-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-weight: 500;
}
.timesheet_table .styled-table th {
  background-color: #2b3767;
  font-weight: bold;
  color: #fff;
  padding: 10px;
}
.timesheet_table .inner-table {
  width: 100%;
  border-collapse: collapse;
}
.timesheet_table .table_scroll {
  height: 200px;
  overflow: auto;
}
.timesheet_table .inner-table th,
.timesheet_table .inner-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.timesheet_table .inner-table th {
  background-color: #2b3767;
  font-weight: bold;
  color: #fff;
  padding: 10px;
  font-size: 15px;
}
.timesheet_table .table_Filter form {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.timesheet_table .edit_btn {
  border: none;
  background: #2b3767;
  color: #fff;
  padding: 8px 30px;
}
.timesheet_table .edit_btn svg {
  width: 19px;
  margin-right: 3px;
}
.timesheet_table .filter-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.timesheet_table .filter-item input {
  padding: 5px 15px;
}
.timesheet_table .filter-label {
  font-size: 16px;
  font-weight: 500;
  color: #2b3767;
  padding: 0px 13px;
}
.timesheet_table .table-container {
  overflow: auto;
}
.timesheet_table .filter-select {
  padding: 5px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  background-color: #fff;
  outline: none;
  transition: all 0.3s ease;
}
.timesheet_table .filter-select:hover,
.timesheet_table .filter-select:focus {
  border-color: #2b3767;
  box-shadow: 0 0 5px #2b3767;
}
.timesheet_table .filter-select option {
  padding: 10px;
}
@media (max-width: 768px) {
  .timesheet_table .table_Filter form {
    flex-direction: column;
  }
  .timesheet_table .filter-item {
    width: 100%;
  }
}
