.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu p span {
  font-size: 12px;
  font-weight: 500;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 10px 0px;
}

.introdu h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.introdu ul li::marker {
  color: var(--primary);
}
.introdu h6 {
  font-size: 29px;
  font-weight: 400;
  line-height: 41px;
}

.introdu .no {
  width: 40px;
  height: 40px;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.introdu .no p {
  color: #fff;
}
.silder-box1 .ss1-btn {
  background-color: var(--primary);
  padding: 6px 50px !important;
  border-radius: 30px;
}
.ss1-btn p {
  color: #fff;
}
.silder-box2 .steps a {
  background: var(--primary);
  width: fit-content;
  padding: 5px 30px;
  border-radius: 10px;
  display: block;
  font-size: 20px;
  font-weight: 800;
  color: white;
}
.silder-box2 .steps {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.intro .container-xxl {
  overflow-x: hidden;
}
.human .container-xxl {
  overflow-x: hidden;
}
.nav-tabs .nav-link.active {
  background: #fff !important;
  display: block;
}
.onclick-dot {
  position: absolute;
  top: 27%;
  transform: translateY(-100%);
  right: 56%;
}
.onclick-dot i {
  color: var(--primary);
  transform: rotate(180deg);
}
.onclick-dot1 i {
  color: #fff;
  transform: rotate(180deg);
}
.onclick-dot2 i {
  color: #fff;
  transform: rotate(180deg);
}
.onclick-dot3 i {
  color: #fff;
  transform: rotate(180deg);
}
.bg-red {
  height: 40px;
  width: 40px;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-red i {
color:white;
}
.onclick-dot1 {
  position: absolute;
  top: 29%;
  transform: translateY(-100%);
  left: 71%;
}
.onclick-dot2 {
  position: absolute;
  top: 54%;
  transform: translateY(-100%);
  right: 25%;
}
.safe-info4 {
  position: absolute;
  top: 31%;
  background: #fff;
  width: 64%;
  padding: 16px;
  left: 3%;
  border-bottom: 3px solid var(--primary);
  height: 189px;
  overflow: auto;
}
.active-red {
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active-red i {
  color: var(--primary);
}
