@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
/********placeHolder********/
header nav .navbar-branda {
  width: 150px;
}
header nav .login_btn a {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
header nav .login_btn a:hover {
  color: #2B3767;
}

.main_banner {
  background-image: url(../images/banner_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
}
.main_banner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(3, 112, 241, 0.82) 0%, rgba(255, 255, 255, 0.82) 100%);
}
.main_banner .banner_text {
  border: 5px solid #fff;
  position: relative;
  z-index: 98;
  padding: 100px 150px;
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 1199.98px) {
  .main_banner .banner_text {
    padding: 30px;
    margin: 0;
  }
}
.main_banner .banner_text::after {
  content: "";
  position: absolute;
  top: -14px;
  right: -2px;
  width: 21%;
  height: 100px;
  background-image: url(../images/banner_lw.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media only screen and (max-width: 767.98px) {
  .main_banner .banner_text::after {
    display: none;
  }
}
.main_banner .banner_text::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: -4px;
  width: 216px;
  height: 100px;
  background-image: url(../images/banner_rw.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media only screen and (max-width: 767.98px) {
  .main_banner .banner_text::before {
    display: none;
  }
}
.main_banner .banner_text h1 {
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  color: #fff;
  font-family: "Archivo", sans-serif;
}
@media only screen and (max-width: 767.98px) {
  .main_banner .banner_text h1 {
    font-size: 35px;
  }
}

.client {
  margin-top: -17rem;
  position: relative;
  z-index: 98;
}
@media only screen and (max-width: 1199.98px) {
  .client {
    margin: 0;
  }
}
.client .client_box {
  background: linear-gradient(0.86deg, #f2f1f1 0.73%, #0084e4 99.27%);
  padding: 35px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.client .client_box .icon_social {
  width: 70px;
  margin: 0 auto;
}
.client .client_box h3 {
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: #fff;
}
.client .client_box p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: rgb(32, 42, 128);
}
.client .client_box .join_btn {
  background: linear-gradient(90deg, #469bff 0%, #2a5d99 100%);
  width: 210px;
  height: 56px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.client .mid_client .icon_social {
  width: 112px;
}

.chosse_banner {
  overflow: hidden;
  background-image: url(../images/chosse-b.JPG);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  position: relative;
}
.chosse_banner::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-color: rgba(44, 135, 240, 0.49);
  width: 100%;
  height: 100%;
}
.chosse_banner h3 {
  color: #fff;
  font-size: 35px;
  font-weight: 700;
}
.chosse_banner .clip_left {
  -webkit-clip-path: polygon(80% 40%, 0 75%, 15% 100%);
          clip-path: polygon(80% 40%, 0 75%, 15% 100%);
  background-color: #fff;
  height: 100%;
  width: 227px;
  position: absolute;
  top: 9px;
  left: -43px;
}
.chosse_banner .clip_right {
  -webkit-clip-path: polygon(80% 40%, 0 75%, 15% 100%);
          clip-path: polygon(80% 40%, 0 75%, 15% 100%);
  background-color: #fff;
  height: 100%;
  width: 227px;
  position: absolute;
  top: -4%;
  right: -37px;
  transform: rotate(180deg);
}

.discover h4 {
  font-size: 30px;
  font-weight: 700;
  color: #426296;
  text-align: center;
}
.discover p {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.discover p span {
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.business {
  background-color: #6ba8f0;
}
.business h4 {
  color: #fff;
  font-size: 30px;
  position: relative;
  font-weight: 700;
}
.business h4::before {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 150px;
  height: 5px;
  border-radius: 5px;
  background-color: #fff;
}
.business .bus-box .w-cicle {
  padding: 10px;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  width: -moz-fit-content;
  width: fit-content;
}
.business .bus-box .w-cicle .icon_fitw {
  width: 50px;
  height: 50px;
}
.business .bus-box h5 {
  color: #fff;
  font-size: 20px;
  position: relative;
  font-weight: 700;
}
.business .bus-box h5::before {
  content: "";
  position: absolute;
  bottom: -8px;
  width: 100px;
  height: 5px;
  border-radius: 5px;
  background-color: #fff;
}
.business .bus-box h5::after {
  content: "";
  content: "";
  position: absolute;
  bottom: -6px;
  width: 100%;
  height: 1px;
  border-radius: 5px;
  background-color: #fff;
  right: 0;
}
.business .bus-box p {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
}

.professionals {
  background-color: #000;
}
.professionals h4 {
  color: #fff;
  font-size: 60px;
  font-weight: 700;
}
.professionals h4 span {
  color: #fc9700;
}
.professionals p {
  font-size: 28px;
  color: #ffffff;
}
.professionals .professionals_list {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: "pro1 pro5 pro9 pro13 pro17 pro21" "pro2 pro6 pro10 pro14 pro18 pro22" "pro3 pro7 pro11 pro15 pro19 pro23" "pro4 pro8 pro12 pro16 pro20 pro24";
}
.professionals .professionals_list .pro1 {
  grid-area: pro1;
}
.professionals .professionals_list .pro2 {
  grid-area: pro2;
}
.professionals .professionals_list .pro3 {
  grid-area: pro3;
}
.professionals .professionals_list .pro4 {
  grid-area: pro4;
}
.professionals .professionals_list .pro5 {
  grid-area: pro5;
}
.professionals .professionals_list .pro6 {
  grid-area: pro6;
}
.professionals .professionals_list .pro7 {
  grid-area: pro7;
}
.professionals .professionals_list .pro8 {
  grid-area: pro8;
}
.professionals .professionals_list .pro9 {
  grid-area: pro9;
  border-right: 0px !important;
}
.professionals .professionals_list .pro10 {
  grid-area: pro10;
  border-right: 0px !important;
}
.professionals .professionals_list .pro11 {
  grid-area: pro11;
  border-right: 0px !important;
}
.professionals .professionals_list .pro12 {
  grid-area: pro12;
  border-right: 0px !important;
}
.professionals .professionals_list .pro13 {
  grid-area: pro13;
  border-right: 0px !important;
}
.professionals .professionals_list .pro14 {
  grid-area: pro14;
  border-right: 0px !important;
}
.professionals .professionals_list .pro15 {
  grid-area: pro15;
  border-right: 0px !important;
}
.professionals .professionals_list .pro16 {
  grid-area: pro16;
  border-right: 0px !important;
}
.professionals .professionals_list .pro17 {
  grid-area: pro17;
}
.professionals .professionals_list .pro18 {
  grid-area: pro18;
}
.professionals .professionals_list .pro19 {
  grid-area: pro19;
}
.professionals .professionals_list .pro20 {
  grid-area: pro20;
}
.professionals .professionals_list .pro21 {
  grid-area: pro21;
}
.professionals .professionals_list .pro22 {
  grid-area: pro22;
}
.professionals .professionals_list .pro23 {
  grid-area: pro23;
}
.professionals .professionals_list .pro24 {
  grid-area: pro24;
}
.professionals .professionals_list .pro_text {
  border: 1px solid rgb(0, 0, 0);
  display: flex;
  align-items: center;
  margin-top: -1px;
  margin-left: -1px;
  height: 180px;
  padding: 15px;
}
.professionals .professionals_list .pro_text p {
  color: #000;
  text-align: center;
  width: 100%;
  font-size: 25px;
  font-weight: 700;
}
.professionals .professionals_list .pro_text .check-icon {
  background-color: #f9a569;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.professionals .professionals_list .pro_text .check-icon i {
  color: #fff;
  font-size: 15px;
}
.professionals .professionals_list .bgsky_blue {
  background: linear-gradient(180deg, #73aff7 59.5%, #0370f1 100%);
}
.professionals .professionals_list .bgsky_blue p {
  color: #fff;
}
.professionals .gettouch {
  padding: 20px 50px;
  border-radius: 20px;
  background-color: #0082ca;
  background: linear-gradient(90deg, #2c86f0 0%, #5f9fea 100%);
  padding: 1x;
  color: white;
  font-size: 32px;
  font-weight: 400;
  line-height: 34.82px;
}

.employees h4 {
  color: #426296;
  font-size: 30px;
  position: relative;
  font-weight: 700;
}
.employees h4::before {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 150px;
  height: 5px;
  border-radius: 5px;
  background-color: #426296;
}
.employees h5 {
  font-size: 30px;
  font-weight: 700;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  margin-left: -80px;
  background-image: linear-gradient(90deg, #fc9700 0%, #965a00 100%);
}
.employees p {
  font-size: 45px;
  font-weight: 700;
  color: #fc9700;
  background-image: linear-gradient(180deg, #fc9700 0%, #965a00 100%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.feature {
  background-color: #ecf4fe;
  position: relative;
}
.feature h4 {
  font-size: 64px;
  font-weight: 400;
  line-height: 70px;
  color: rgba(7, 7, 7, 0.7411764706);
  text-align: center;
}
.feature .feat-box {
  padding: 20px;
}
.feature .feat-box .icon-fit_f {
  width: 100px;
}
.feature .feat-box h6 {
  font-size: 25px;
  color: #100f0f;
  font-weight: 600;
}
.feature .feat-box p {
  font-size: 18px;
  color: #0a0909;
}

.feature_client {
  background-color: #ecf4fe;
  position: relative;
}
.feature_client h4 {
  font-size: 40px;
  font-weight: 500;
  color: #001c71;
  text-align: center;
}
.feature_client .feat-box {
  padding: 20px;
}
.feature_client .feat-box .icon-fit_f {
  width: 100px;
}
.feature_client .feat-box h6 {
  font-size: 25px;
  color: #001c71;
  font-weight: 600;
}
.feature_client .feat-box p {
  font-size: 18px;
  color: #001c71;
}

.our-partner1 h4 {
  font-size: 30px;
  font-weight: 700;
  color: #000000;
}
.our-partner1 p {
  font-size: 22px;
  color: #0b0b0b;
}

.our-partner h4 {
  font-size: 40px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  background: rgba(217, 217, 217, 0.34);
  padding: 15px;
}
.our-partner p {
  font-size: 32px;
  color: #0b0b0b;
  font-weight: 300;
  line-height: 38px;
}

.get_intouch {
  overflow-x: hidden;
}
.get_intouch .bg-get_intouch {
  position: relative;
  background-color: #001c71;
}
.get_intouch .bg-get_intouch h4 {
  font-size: 45px;
  font-weight: 700;
  color: white;
}
.get_intouch .bg-get_intouch h4 span {
  color: #fc9700;
}
.get_intouch .bg-get_intouch a {
  background-color: #fc9700;
  padding: 15px 35px;
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  font-size: 20px;
}
.get_intouch .bg-get_intouch .clip_left {
  -webkit-clip-path: polygon(80% 40%, 0 75%, 15% 100%);
          clip-path: polygon(80% 40%, 0 75%, 15% 100%);
  background-color: #fff;
  height: 100%;
  width: 227px;
  position: absolute;
  top: 9px;
  left: -43px;
}
.get_intouch .bg-get_intouch .clip_right {
  -webkit-clip-path: polygon(80% 40%, 0 75%, 15% 100%);
          clip-path: polygon(80% 40%, 0 75%, 15% 100%);
  background-color: #fff;
  height: 100%;
  width: 227px;
  position: absolute;
  top: -4%;
  right: -37px;
  transform: rotate(180deg);
}

@media (max-width: 1200px) {
  .professionals_list {
    width: 100%;
    overflow-x: auto;
  }
}
@media (max-width: 900px) {
  .professionals_list {
    width: 100%;
    overflow-x: auto;
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .professionals_list {
    width: 100%;
    overflow-x: auto;
    margin: 0 auto;
  }
  .employees h5 {
    font-size: 20px;
    font-weight: 700;
    color: #fc9700;
    margin-left: 0px;
  }
  .employees p {
    font-size: 35px;
    font-weight: 700;
    color: #fc9700;
  }
}
@media (max-width: 600px) {
  .get_intouch .bg-get_intouch .clip_left {
    display: none;
  }
  .get_intouch .bg-get_intouch .clip_right {
    display: none;
  }
  .chosse_banner .clip_left {
    display: none;
  }
  .chosse_banner .clip_right {
    display: none;
  }
  .banner .clip_left {
    display: none;
  }
  .banner .clip_right {
    display: none;
  }
  .banner .join-us {
    padding: 20px;
  }
  .banner .join-us h3 {
    font-size: 25px;
  }
  .banner .pw-top {
    margin-top: 0px;
  }
}
.logo-slider .logo-img {
  width: -moz-fit-content;
  width: fit-content;
  height: 150px;
}
.logo-slider .slick-track {
  display: flex;
  gap: 70px;
  justify-content: center;
  align-items: center;
}
.logo-slider .logo-img-h {
  height: 100px;
}

.mobile_screen {
  background: linear-gradient(180deg, #469bff 0%, #2a5d99 100%);
}
.mobile_screen h4 {
  font-size: 32px;
  font-weight: 500;
  line-height: 38.73px;
  color: #fff;
}
.mobile_screen h5 {
  font-size: 82px;
  font-weight: 800;
  line-height: 100px;
  color: #9eff00;
}
@media only screen and (max-width: 991.98px) {
  .mobile_screen h5 {
    font-size: 55px;
    line-height: normal;
  }
}
.mobile_screen h5 span {
  font-weight: 500;
  color: #fff;
}

.work_ji {
  background: linear-gradient(105.57deg, #fc9700 0.35%, #0e0800 10.79%, #090909 78.83%, #000000 87.17%, #928f8f 99.78%);
}
.work_ji h4 {
  font-size: 82px;
  font-weight: 500;
  line-height: 100px;
  color: #fc9700;
}
@media only screen and (max-width: 991.98px) {
  .work_ji h4 {
    font-size: 55px;
    line-height: normal;
  }
}
.work_ji p {
  font-size: 35px;
  font-weight: 500;
  line-height: 48px;
  color: #fff;
}
.work_ji .video_play {
  background-color: #fff;
  padding: 35px;
  border-radius: 10px;
}
.work_ji .video_play a {
  width: 259px;
  height: 60px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.05px;
  background: #469bff;
  color: #fff;
}
.work_ji .video_play h6 {
  font-size: 32px;
  font-weight: 500;
  line-height: 52px;
  color: #000;
}/*# sourceMappingURL=main.css.map */