.start p {
  font-size: 17px;
  line-height: 17px;
  color: rgb(41, 41, 41);
  font-weight: 500;
}
.start-quiz a {
  color: var(--primary);
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 1px;
}
.legi p {
  font-size: 17px;
  line-height: 33px;
  color: rgb(0, 0, 0);
  font-weight: 400;
}
#Organisation h6 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}
.human p {
  font-size: 17px;
  line-height: 33px;

  font-weight: 400;
}
.human .no1 p {
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.human .no1 {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  height: 40px;
}
.accordion-item h3 {
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  color: black;
}
button.accordion-button.rounded-0.collapsed {
  border-bottom: 1px solid #dee2e6;
}
#Worker .expect {
  width: 100%;
  height: 400px;
  background-image: url(images/expact.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#Worker .expect .overlay-box {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
}
#Worker .expect .overlay-box .w-line {
  width: 100px;
  height: 4px;
  background: white;
}
#Worker .expect .overlay-box h1 {
  font-size: 32px;
  font-weight: 700;
  color: rgb(255, 255, 255);
}
.risk .safe {
  width: 183px;
  padding: 10px 17px;
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.risk .safe h5 {
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  color: white;
}
.risk .safe-info .safe-please p {
  font-size: 17px;
  line-height: 25px;
  color: black;
}
.risk .safe-info .safe-please:after {
  right: 101%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 20px;
  margin-top: -20px;
}
.risk .safe-info .navigation i {
  cursor: pointer;
}
.risk .safe-info .navigation i:hover {
  color: var(--primary);
}
.risk .safe-info {
  width: 353px;
  border: none;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 207px;
  position: absolute;
  display: none;
  z-index: 9;
  transform: translateY(-50%);
}
.safe1 {
  position: absolute;
  top: 10%;
  left: 10%;
}
.risk .safe .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 12px;
  cursor: pointer;

  position: absolute;
  bottom: 3px;
  right: 6px;
  border: 2px solid transparent;
}
.risk .safe .icon:hover {
  border: 2px solid red;
}

.risk .safe2 {
  width: 183px;
  padding: 10px 17px;
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.risk .safe2 h5 {
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  color: white;
}
.risk .safe-info2 .safe-please p {
  font-size: 17px;
  line-height: 25px;
  color: black;
}
.risk .safe-info2 .safe-please:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #ffffff;
  border-width: 30px;
  margin-top: -30px;
}
.risk .safe-info2 .navigation i {
  cursor: pointer;
}
.risk .safe-info2 .navigation i:hover {
  color: var(--primary);
}
.risk .safe-info2 {
  width: 353px;
  border: none;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  top: 50%;
  right: 207px;
  position: absolute;
  display: none;
  z-index: 9;
  transform: translateY(-50%);
}
.safe2 {
  position: absolute;
  top: 10%;
  right: 10%;
}
.risk .safe2 .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 12px;
  cursor: pointer;

  position: absolute;
  bottom: 3px;
  left: 6px;
  border: 2px solid transparent;
}
.risk .safe2 .icon:hover {
  border: 2px solid red;
}

.risk .safe3 {
  width: 183px;
  padding: 10px 17px;
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.risk .safe3 h5 {
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  color: white;
}
.risk .safe-info3 .safe-please p {
  font-size: 17px;
  line-height: 25px;
  color: black;
}
.risk .safe-info3 .safe-please:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #ffffff;
  border-width: 30px;
  margin-top: -30px;
}
.risk .safe-info3 .navigation i {
  cursor: pointer;
}
.risk .safe-info3 .navigation i:hover {
  color: var(--primary);
}
.risk .safe-info3 {
  width: 353px;
  border: none;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  top: 50%;
  right: 207px;
  position: absolute;
  display: none;
  z-index: 9;
  transform: translateY(-50%);
}
.safe3 {
  position: absolute;
  top: 30%;
  right: 4%;
  transform: translateY(-30%);
}
.risk .safe3 .icon:hover {
  border: 2px solid red;
}
.risk .safe3 .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 12px;
  cursor: pointer;

  position: absolute;
  bottom: 3px;
  left: 6px;
  border: 2px solid transparent;
}

.risk .safe4 {
  width: 183px;
  padding: 10px 17px;
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.risk .safe4 h5 {
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  color: white;
}
.risk .safe-info3 .safe-please p {
  font-size: 17px;
  line-height: 25px;
  color: black;
}
.risk .safe-info4 .safe-please:after {
  right: 101%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 20px;
  margin-top: -20px;
}
.risk .safe-info4 .navigation i {
  cursor: pointer;
}
.risk .safe-info4 .navigation i:hover {
  color: var(--primary);
}
.risk .safe-info4 {
  width: 353px;
  border: none;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 207px;
  position: absolute;
  display: none;
  z-index: 9;
  transform: translateY(-50%);
}
.safe4 {
  position: absolute;
  top: 30%;
  left: 4%;
  transform: translateY(-30%);
}
.risk .safe4 .icon:hover {
  border: 2px solid red;
}
.risk .safe4 .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 12px;
  cursor: pointer;

  position: absolute;
  bottom: 3px;
  right: 6px;
  border: 2px solid transparent;
}

.risk .safe5 {
  width: 183px;
  padding: 10px 17px;
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.risk .safe5 h5 {
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  color: white;
}
.risk .safe-info5 .safe-please p {
  font-size: 17px;
  line-height: 25px;
  color: black;
}
.risk .safe-info5 .safe-please:after {
  right: 101%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 20px;
  margin-top: -20px;
}
.risk .safe-info5 .navigation i {
  cursor: pointer;
}
.risk .safe-info5 .navigation i:hover {
  color: var(--primary);
}
.risk .safe-info5 {
  width: 353px;
  border: none;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 207px;
  position: absolute;
  display: none;
  z-index: 9;
  transform: translateY(-50%);
}
.safe5 {
  position: absolute;
  top: 50%;
  left: 4%;
  transform: translateY(-30%);
}
.risk .safe5 .icon:hover {
  border: 2px solid red;
}
.risk .safe5 .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 12px;
  cursor: pointer;

  position: absolute;
  bottom: 3px;
  right: 6px;
  border: 2px solid transparent;
}

.risk .safe6 {
  width: 200px;
  padding: 10px 17px;
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.risk .safe6 h5 {
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  color: white;
}
.risk .safe-info6 .safe-please p {
  font-size: 17px;
  line-height: 25px;
  color: black;
}
.risk .safe-info6 .safe-please:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #ffffff;
  border-width: 30px;
  margin-top: -30px;
}
.risk .safe-info6 .navigation i {
  cursor: pointer;
}
.risk .safe-info6 .navigation i:hover {
  color: var(--primary);
}
.risk .safe-info6 {
  width: 353px;
  border: none;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  top: 50%;
  right: 207px;
  position: absolute;
  display: none;
  z-index: 9;
  transform: translateY(-50%);
}
.safe6 {
  position: absolute;
  top: 50%;
  right: 4%;
  transform: translateY(-30%);
}
.risk .safe6 .icon:hover {
  border: 2px solid red;
}
.risk .safe6 .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 12px;
  cursor: pointer;

  position: absolute;
  bottom: 3px;
  left: 6px;
  border: 2px solid transparent;
}
.risk .safe8 {
  width: 200px;
  padding: 10px 17px;
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.risk .safe8 h5 {
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  color: white;
}
.risk .safe-info8 .safe-please p {
  font-size: 17px;
  line-height: 25px;
  color: black;
}
.risk .safe-info8 .safe-please:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #ffffff;
  border-width: 30px;
  margin-top: -30px;
}
.risk .safe-info8 .navigation i {
  cursor: pointer;
}
.risk .safe-info8 .navigation i:hover {
  color: var(--primary);
}
.risk .safe-info8 {
  width: 353px;
  border: none;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  top: 50%;
  right: 207px;
  position: absolute;
  display: none;
  z-index: 9;
  transform: translateY(-50%);
}
.safe8 {
  position: absolute;
  top: 73%;
  right: 10%;
  transform: translateY(-30%);
}
.risk .safe8 .icon:hover {
  border: 2px solid red;
}
.risk .safe8 .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 12px;
  cursor: pointer;

  position: absolute;
  bottom: 3px;
  left: 6px;
  border: 2px solid transparent;
}
.risk .safe7 {
  width: 183px;
  padding: 10px 17px;
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.risk .safe7 h5 {
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  color: white;
}
.risk .safe-info7 .safe-please p {
  font-size: 17px;
  line-height: 25px;
  color: black;
}
.risk .safe-info7 .safe-please:after {
  right: 101%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 20px;
  margin-top: -20px;
}
.risk .safe-info7 .navigation i {
  cursor: pointer;
}
.risk .safe-info7 .navigation i:hover {
  color: var(--primary);
}
.risk .safe-info7 {
  width: 353px;
  border: none;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 207px;
  position: absolute;
  display: none;
  z-index: 9;
  transform: translateY(-50%);
}
.safe7 {
  position: absolute;
  top: 73%;
  left: 10%;
  transform: translateY(-30%);
}
.risk .safe7 .icon:hover {
  border: 2px solid red;
}
.risk .safe7 .icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 12px;
  cursor: pointer;

  position: absolute;
  bottom: 3px;
  right: 6px;
  border: 2px solid transparent;
}
.zn{
  z-index: 99 !important;
}
button#nav-adequate-tab{
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
  padding: 27px 0px;
  background-color: #efefef;
}
button#nav-adequate-tab:focus{
  background-color: #ffff;
  color: var(--primary);
}
button#nav-information-tab{
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
  padding: 27px 0px;
  background-color: #efefef;
}
button#nav-information-tab:focus{
  background-color: #ffff;
  color: var(--primary);
}
button#nav-training-tab{
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
  padding: 27px 0px;
  background-color: #efefef;
}
button#nav-training-tab:focus{
  background-color: #ffff;
  color: var(--primary);
}
button#nav-site-tab{
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
  padding: 27px 0px;
  background-color: #efefef;
}
button#nav-site-tab:focus{
  background-color: #ffff;
  color: var(--primary);
}

@media (max-width:1024px) {
  .risk .safe h5 {
    font-size: 16px;
    font-weight: 500;
}
.risk .safe .icon {
  position: sticky;
  aspect-ratio: 1;
}
.risk .safe{
  width: auto;
}
.risk .safe1{
  padding: 0px;
  width: auto;
}
.risk .safe2{
  padding: 0px;
  width: auto;
}
.risk .safe3{
  padding: 0px;
  width: auto;
}
.risk .safe4{
  padding: 0px;
  width: auto;
}
.risk .safe5{
  padding: 0px;
  width: auto;
}
.risk .safe6{
  padding: 0px;
  width: auto;
}
.risk .safe7{
  padding: 0px;
  width: auto;
}
.risk .safe8{
  padding: 0px;
  width: auto;
}

}
@media (max-width:768px) {
  .risk .safe h5 {
    font-size: 12px;
    font-weight: 500;
}
.safe7 {
  position: absolute;
  top: 73%;
  left: -3%;
  transform: translateY(-30%);
}
.safe8 {
  position: absolute;
  top: 73%;
  right: -3%;
  transform: translateY(-30%);
}
.safe6 {
  position: absolute;
  top: 50%;
  right: -3%;
  transform: translateY(-30%);
}
.safe5 {
  position: absolute;
  top: 50%;
  left: -9px;
  transform: translateY(-30%);
  width: auto !important;
  right: auto;
}
.safe4 {
  position: absolute;
  top: 30%;
  left: -3%;
  transform: translateY(-30%);
}
.safe3 {
  position: absolute;
  top: 30%;
  right: -3%;
  transform: translateY(-30%);
}
.safe2 {
  position: absolute;
  top: 10%;
  right: -3%;
}
.safe1 {
  position: absolute;
  top: 10%;
  left: -3%;
}
.risk .safe-info  {
  position: sticky;
}
.risk .safe-info2  {
  position: sticky;
}
.risk .safe-info3  {
  position: sticky;
}
.risk .safe-info4  {
  position: sticky;
}
.risk .safe-info5  {
  position: sticky;
}
.risk .safe-info6 {
  position: sticky;
}
.risk .safe-info7  {
  position: sticky;
}
.risk .safe-info8  {
  position: sticky;
}
}