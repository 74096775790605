:root {
  --primary: #c62026;
  --secondary: #293867;
  --white: #fff;
  --black: #111;
}
.health-block {
  background: url(./images/s1.jpg) no-repeat center;
  background-size: cover;
  height: 80vh;
}
.health-block .overlay-box {
  background: rgba(0, 0, 0, 0.7);
}
.course-textblock h1 {
  color: #fff;
  font-weight: 800;
  font-size: 45px;
}


.start-btn a {
  color: #222;
  display: inline-block;
  padding: 10px 30px;
  font-size: 14px;
  border-radius: 100px;
  background: #fff;
}
.bright-content-block .text-block h2 {
  color: #222;
  font-size: 22px;
  font-weight: 600;
  line-height: 34px;
}
.bright-content-block .text-block h5 {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: underline;
}
.bright-content-block .text-block p {
  color: #222;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.circle-small {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0px;
  text-align: center;
}
.circle-small .fa-check-circle {
  color: var(--primary);
  font-size: 16px;
}
.circle-small svg {
  width: 80px;
  height: 80px;
  transform: scale(0.3);
  margin: -25px 0 0 -25px;
}

.circle-small .bg {
  fill: none;
  stroke-width: 6px;
  stroke: #ccc;
}

.circle-small .progress {
  fill: none;
  stroke-width: 6px;
  stroke: var(--primary);
  stroke-linecap: round;
  stroke-dasharray: 232.36;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.circle-small .progress.one {
  stroke-dashoffset: 80;
  /* animation: one 2.5s ease-in-out, appear 1s; */
}

.circle-small .progress.two {
  stroke-dashoffset: 233;
  /* animation: two 3.5s ease-in-out, appear 2s; */
}

.circle-small .text {
  position: absolute;
  width: 100%;
  top: 26px;
  font-size: 17px;
  text-align: center;
  font-weight: 400;
  line-height: 16px;
}
.pyramid-img {
  width: 400px;
}

.circle-small .text .small {
  font-size: 12px;
  color: #fff;
}
.pie .pie-text-1 {
  position: absolute;
  top: 39%;
  right: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transform: translateX(50%);
}
.pie .pie-text-1 i {
  font-size: 78px;
  color: var(--primary);
}
.pie .percentage {
  position: absolute;
  top: 0;
  right: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pie .refresh {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}
.pie .refresh i {
  font-size: 30px;
  cursor: pointer;
}
.slick-track{
  display: flex !important;
  justify-content: center;
  align-items: center;
}
@keyframes big {
  from {
    stroke-dashoffset: 326.56;
  }
  to {
    stroke-dashoffset: 60;
  }
}

@keyframes one {
  from {
    stroke-dashoffset: 232.36;
  }
  to {
    stroke-dashoffset: 80;
  }
}

@keyframes two {
  from {
    stroke-dashoffset: 232.36;
  }
  to {
    stroke-dashoffset: 140;
  }
}

@keyframes appear {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.list-link {
  padding: 6px 10px;
}
.list-link:hover {
  background: #f3f3f3;
}
.list-link i {
  color: #999;
}
.list-link h4 {
  color: #222;
  font-size: 13px;
}
.disable-box {
  cursor: no-drop !important;
}
.disable-box h4 {
  color: #999;
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
    opacity: 0;
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
    opacity: 0;
  }
}
.scroll-height {
  height: 100vh;
  overflow-y: auto;
  width: 280px;
  overflow-x: hidden;
  background: #fafafa;
}
.top-title {
  padding: 33px 22px 20px 22px;
  background: var(--primary);
}
.top-title h1 {
  color: var(--white);
  font-size: 28px;
  font-weight: 600;
}
.progress-bar-full {
  background: #fff;
}
.progress-bar-full > div {
  height: 2px;
  background: var(--black);
}
.pro-number {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--white);
}
.scroll-height .side-navbar a {
  padding: 6px 20px;
}
.scroll-height .side-navbar .active {
  border-left: 4px solid var(--primary);
}
.backpage {
  background: #f0f0f0;
  padding: 10px;
  display: block;
  color: var(--black);
}
.backpage h5 {
  font-size: 15px;
  text-transform: capitalize;
}
.backpage:hover {
  background: #e3e3e3;
}
.backpage:hover h5 {
  text-decoration: underline;
}
.tbtn {
  background: var(--white);
  border: none;
  color: #000;
  padding: 10px;
}
.close-box .side-layer {
  width: 0px;
  overflow: hidden;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
.close-box .side-layer {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
.side-layer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}
.rightpart {
  margin-left: 280px;
  width: calc(100% - 280px);
}
.close-box .rightpart {
  margin-left: 0px;
}

/* new page */
.intro .prev {
  background-color: #f0f0f0;
  height: 61px;
  color: black;
  font-weight: 600;
}
.intro .prev:hover p {
  text-decoration: underline;
}
.intro .nav-icon {
  font-size: 22px;
  cursor: pointer;
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
    opacity: 0;
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
    opacity: 0;
  }
}
.comp {
  border: 1px solid #dddddd;
  padding: 1rem 1.5rem;
}
.comp i {
  width: 50px;
}
.comp p {
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
}

.border-line {
  border-top: 1px solid #dddddd;
  width: 100%;
}
#on-plus {
  display: none;
}
.intro .box {
  background-color: #ffff;
  border: 1px solid #dddddd;
  padding: 2rem;
}
.intro .legal {
  background-color: #ffff;
  border: 1px solid #dddddd;
  padding: 2rem;
  cursor: pointer;
}
.le-drop {
  display: none;
}
.intro .legal p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #333333;
}
.intro .legal h3 {
  font-size: 18px;
  line-height: 25.2px;
  color: black;
  font-weight: 700;
}
.intro .legal i {
  color: #828080;
}
.border-line .line-per {
  width: 150px;
  height: 2px;
  background-color: var(--primary);
}
.intro h4 {
  font-size: 12px;
  line-height: 21px;
  font-weight: 600;
  font-style: italic;
  color: #000;
}

.intro-click {
  padding-left: 0px;
}
.intro h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  color: #000;
}
.padding {
  padding: 0px 70px;
}
.intro h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: #000;
}
.intro .line {
  margin-top: 15px;
  height: 4px;
  width: 200px;
  background-color: var(--primary);
}

.intro .intro-img img {
  border: 1px solid #dddddd;
  aspect-ratio: 1;
  width: 100%;
}
.intro .intro-img button {
  color: black;
  position: absolute;
  bottom: 3px;
  color: black;
  position: absolute;
  bottom: 7px;
  right: 19px;
  background: #cdcdcd75;
  width: 25px;
  display: flex;
  height: 25px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  right: 19px;
}
.introdu .accordion-button {
  border-left: 4px solid transparent !important;
  font-weight: 600;
}
.introdu .accordion-button:not(.collapsed) {
  background-color: white;
  border-left: 4px solid var(--primary) !important;
}
.introdu .accordion-body:not(.collapsed) {
  border-left: 4px solid var(--primary);
}
.introdu .accordion-button::after {
  content: "";
  background-image: url(./images/plus.png);
}

.introdu .accordion-button:not(.collapsed)::after {
  background-image: url(./images/neg.png);
}
.carousel-control-prev {
  left: -110px;
}
.carousel-control-next {
  right: -110px;
}
.btn-block button {
  background: var(--primary);
  color: #fff;
  padding: 15px 10px;
  max-width: 100%;
  width: 100%;
  border: none;
}
.silder-box1 {
  background-color: white;
}
.silder-box2 {
  background-color: white;
}
.silder-box1 h5 {
  font-size: 30px;
  color: black;
  font-weight: 600;
  line-height: 40px;
}
.silder-box1 p {
  font-size: 16px;
  font-weight: 500;
}
.silder-box1 .ss1-btn {
  background-color: var(--primary);
  padding: 10px 50px;
  border-radius: 30px;
}
.silder-box1 .ss1-btn a {
  color: white;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .padding {
    padding: 0px 30px;
  }
}

#conti-btn button {
  width: 100%;
  background-color: var(--primary);
  height: 57px;
  border: none;
  color: white;
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
}
#Injury h5 {
  font-size: 22px;
  line-height: 33px;
  font-weight: 700;
  color: black;
  text-align: center;
}
#Injury span {
  font-size: 12px;
  line-height: 23px;
  font-weight: 400;
  color: black;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .padding {
    padding: 0px 30px;
  }
  div#carouselExample1 {
    padding: 0px 17px;
  }
  div#carouselExample {
    padding: 0px 17px;
  }
  .carousel-control-prev {
    left: -26px;
  }
  .carousel-control-next {
    right: -22px;
  }
}

/* <------------------legi>.............. */

.health {
  border-left: 5px solid var(--primary);
  padding: 50px 20px;
  box-shadow: 0 0.2rem 3rem rgba(0, 0, 0, 0.215);
  border-radius: 5px;
}
.health-safety .health h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  font-style: italic;
  color: var(--primary);
}
.health-safety .health p {
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  color: black;
}
.health-safety .s-line {
  height: 81px;
  width: 2px;
  background: #9f9d9d;
}
.health-safety #ul-number {
  list-style: number;
}
h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: black;
}
.enfo h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: black;
}
.enfo p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}

.enfo .safe-img {
  width: 257px;
}

/* <-------------------human-------------------------> */

.human .no {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
}
.human .no p {
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  color: white;
}
.human p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: black;
}
#humn .human-img {
  width: 452px;
  height: 452px;
}

#humn h2 {
  color: var(--primary);
}
.cart-1 .nav-tabs .nav-item.show .nav-link,
.cart-1 .nav-tabs .nav-link.active {
  border: none;
  background-color: transparent;
  border-color: transparent;
}
.cart-1 .nav-tabs .nav-link:focus,
.cart-1 .nav-tabs .nav-link:hover,
.cart-1 .nav-tabs .nav-link:focus-visible {
  /* isolation: isolate; */
  border: none;
  outline: none;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.cart-1.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent;
}
button#nav-home-tab {
  padding: 27px 0px;
  background-color: #efefef;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
}
button#nav-home-tab:focus {
  background-color: #ffff;
  color: var(--primary);
}
button#nav-profile-tab {
  padding: 27px 0px;
  background-color: #efefef;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
}
button#nav-profile-tab:focus {
  background-color: #ffff;

  color: var(--primary);
}
button#nav-contact-tab {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
  padding: 27px 0px;
  background-color: #efefef;
}
button#nav-contact-tab:focus {
  background-color: #ffff;
  color: var(--primary);
}
.acc-img {
  max-width: 505px;
}

.caro-img {
  width: 280.61px;
}

.carousel-item.active h1 {
  font-size: 30px;
  line-height: 37.5px;
  font-weight: 700;
  color: black;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1);
}

/* <--------------------------------lesson4----------------------> */

button#nav-home-tab1 {
  padding: 27px 0px;
  background-color: #efefef;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
}
button#nav-home-tab1:focus {
  background-color: #ffff;
  color: var(--primary);
}
button#nav-profile-tab2 {
  padding: 27px 0px;
  background-color: #efefef;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
}
button#nav-profile-tab2:focus {
  background-color: #ffff;

  color: var(--primary);
}
button#nav-green-tab4 {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
  padding: 27px 0px;
  background-color: #efefef;
}
button#nav-green-tab4:focus {
  background-color: #ffff;
  color: var(--primary);
}
button#nav-contact-tab3 {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
  padding: 27px 0px;
  background-color: #efefef;
}
button#nav-contact-tab3:focus {
  background-color: #ffff;
  color: var(--primary);
}
button#nav-red-tab {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
  padding: 27px 0px;
  background-color: #efefef;
}
button#nav-red-tab:focus {
  background-color: #ffff;
  color: var(--primary);
}
.Safety p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: black;
}
.Safety h2 {
  font-size: 16px;
  line-height: 31px;
  font-weight: 700;
  color: black;
}

/* <--------------------------------lesson5----------------------> */
.quiz .start a {
  color: var(--primary);
}
input#yellow,
input#blue,
#red,
#green {
  width: 22px;
  accent-color: var(--primary);
  cursor: pointer;
}
.q1 span {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.24px;
  font-weight: 700;
  font-style: italic;
  color: black;
}
.q1 span strong {
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.24px;
  font-weight: 900;
  color: var(--primary);
}
.lesson-block h4 {
  font-size: 14px;
  font-weight: 700;
  color: black;
  font-style: italic;
}
.lesson-block h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  color: black;
}
.q1 p {
  font-size: 17px;
  line-height: 34px;
  letter-spacing: 0.24px;
  font-weight: 400;
  color: black;
}
.q1 button {
  padding: 7px 33px;
  border-radius: 30px;
  border: none;
  background: var(--primary);
  color: white;
}
/* <--------------------------------lesson6----------------------> */
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 350px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  /* transition: transform 0.6s;
  transform-style: preserve-3d; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.step6_{
  transition: transform 0.6s;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  border-top: none;
  transition: 0.6 all ease-in-out;
}
.flip-card:hover .flip-card-inner {
  /* transform: rotateY(180deg);
  border-top: none;
  transition: 0.6 all ease-in-out; */
}
.fp-1:hover button#h-btn {
  /* display: none !important; */
  transition: 0.6 all ease-in-out;
  transform: rotateY(180deg);
}
.flip-card-inner {
  border-top: 5px solid var(--primary);
  transition: 0.6 all ease-in-out;
}
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #ffffff;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flip-card-front span {
  font-size: 29px;
  line-height: 36.5px;
  font-weight: 700;
  color: black;
}
.flip-card-back {
  color: black;
  transform: rotateY(180deg);
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 13px;
  padding: 1rem;
}
button#h-btn {
  position: absolute;
  bottom: 23px;
  right: 34px;
}

.report h4 {
  font-size: 32px;
  line-height: 40px;
  color: black;
  font-weight: 700;
}
.report p {
  font-size: 17px;
  line-height: 33px;
  color: black;
  font-weight: 400;
}

.rep-ul li::marker {
  color: var(--primary);
}
.rep-ul li {
  padding-left: 30px;
}

/* <--------------------------------lesson7----------------------> */

.fullname-block h4 {
  color: var(--primary);
  font-size: 30px;
  font-weight: 700;
}
.fullname-block h5 {
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.name-form input[type="text"] {
  font-size: 16px;
  padding: 10px 20px;
  color: #000;
  width: 100%;
  max-width: 500px;
}
.name-form button {
  background: var(--primary);
  color: #fff;
  font-size: 16px;
  padding: 12px 20px;
  border: none;
}
.name-form .form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.name-form .form-check label {
  cursor: pointer;
}

.results-block h3 {
  font-size: 24px;
  color: #000;
}
.results-block h4 {
  font-size: 24px;
}
.results-block button {
  background: var(--primary);
  color: #fff;
  font-size: 16px;
  padding: 12px 20px;
  border: none;
}
.fixnav {
  position: fixed;
  top: 0px;
}

.quiz_cont {
  background: #F71701;
  color: #fff;
  border: 1px solid #F71701;
  padding: 10px;
  border-radius: 6px;
}
.diamod {
  border-radius: inherit;
}
.opacityy{
  pointer-events: none;
}
.check-1 i{
  font-size: 10px;
}
.check-1{
  position: absolute;
}
.carousal-driving{
  display: flex !important;
  justify-content: center;
  align-items: center;
}