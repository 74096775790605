.ch-cl li p strong {
  color: var(--primary);
}
.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 5400;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 10px 0px;
}

.introdu h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.introdu ul li::marker {
  color: var(--primary);
}
.bg-red {
  background-color: var(--primary);
}
.bg-red h5 {
  color: #fff;
}
.bg-red p {
  color: #fff;
}
.ex-main .no1 {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  height: 40px;
}
.ex-main .no1 p {
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-tabs .nav-link.active {
  background: #fff !important;
  display: block;
}
.accordion-header {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 0px !important;
}
.carousel-item .silder-box1 .ss1-btn {
  background-color: var(--primary);
  padding: 6px 50px !important;
  border-radius: 30px;
}
.carousel-item .ss1-btn p {
  color: #fff;
}
.carousel-item .silder-box2 .steps a {
  background: var(--primary);
  width: fit-content;
  padding: 5px 30px;
  border-radius: 10px;
  display: block;
  font-size: 20px;
  font-weight: 800;
  color: white;
}
.carousel-item .silder-box2 .steps {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.last-cl strong {
  color: var(--primary);
}
/* table td,
table th {
  border: 1px solid #ddd;
  padding: 1.7rem 1.5rem 1.5rem;
  text-align: center;
} */
.intro .container-xxl {
  overflow-x: hidden;
}
.human .container-xxl {
  overflow-x: hidden;
}
.card {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 1s;
}

.card.is-flipped {
  transform: translateX(-100%) rotateY(-180deg);
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
  font-weight: 400;
  backface-visibility: hidden;
}

.card__face--back {
  color: #000;
  /* transform: rotateY(180deg); */
}
.scene {
  display: inline-block;
  width: 246px;
  height: 264px;
  margin: 2px 0;

  perspective: 600px;
}
button.btn.btn-secondary {
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
button.btn.btn-secondary i {
  font-size: 14px;
}
.resp-desktop {
  width: 100%;
  height: 400px;
}
.resp-desktop img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.onclick-dot {
  position: absolute;
  top: 26%;
  transform: translateY(-100%);
  right: 61%;
  cursor: pointer;
}
.onclick-dot i {
  color: var(--primary);
  transform: rotate(180deg);
}
.onclick-dot1 i {
  color: #fff;
  transform: rotate(180deg);
}
.onclick-dot2 i {
  color: #fff;
  transform: rotate(180deg);
}
.bg-red {
  height: 40px;
  width: 40px;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.onclick-dot1 {
  position: absolute;
  top: 19%;
  transform: translateY(-100%);
  left: 55%;
  cursor: pointer;
}
.onclick-dot2 {
  position: absolute;
  top: 41%;
  transform: translateY(-100%);
  left: 38%;
  cursor: pointer;
}
.onclick-dot3 {
  position: absolute;
  top: 60%;
  transform: translateY(-100%);
  left: 44%;
  cursor: pointer;
}
.safe-info4 {
  position: absolute;
  top: 46%;
  background: #fff;
  width: 64%;
  padding: 16px;
  left: 3%;
  border-bottom: 3px solid var(--primary);
  height: 189px;
  overflow: auto;
}
.active-red {
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active-red i {
  color: var(--primary);
}
.bg-red i {
  color: #fff;
}
