.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu h2 {
  font-size: 32px;
  line-height: 45px;
  font-weight: 400;
  color: black;
  padding: 10px 0px;
}
.accordion-header {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 0px !important;
}
.introdu h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.introdu h6 {
  font-size: 32px;
  line-height: 48px;
  color: #fff;
  font-weight: 600;
}
.introdu ul li::marker {
  color: var(--primary);
}
.nav-tabs .nav-link.active {
  background: #fff !important;
  display: block;
}
button#nav-profile-tab21 {
  padding: 27px 0px;
  background-color: #efefef;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
}
button#nav-home-tab11 {
  padding: 27px 0px;
  background-color: #efefef;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
}
button#nav-home-tab11:focus {
  background-color: #ffff;
  color: var(--primary);
}
button#nav-profile-tab21:focus {
  background-color: #ffff;
  color: var(--primary);
}
.pre-line {
  background-color: #efefef;
}
.pre-line .top-line {
  height: 5px;
  width: 100px;
  background-color: var(--primary);
}
.pre-line p {
  font-size: 22px;
  font-weight: 700;
  color: #000;
}
.small-size {
  width: 100px;
}
.mid-size {
  height: 400px;
}
.mid-size img {
  height: 100%;
  object-fit: cover;
}
.pre-line1 .top-line {
  height: 5px;
  width: 100px;
  background-color: #fff;
}
.pre-line1 {
  background-image: url(images/prepering-img/img10.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px;
}
.pre-line1 h2 {
  color: #fff !important;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.53);
}
ul > li > ul > li {
  list-style: none;
}
.onclick-dot {
  position: absolute;
  top: 23%;
  transform: translateY(-100%);
  right: 73%;
}
.onclick-dot i {
  color: var(--primary);
  transform: rotate(180deg);
}
.onclick-dot1 i {
  color: #fff;
  transform: rotate(180deg);
}
.onclick-dot2 i {
  color: #fff;
  transform: rotate(180deg);
}
.onclick-dot3 i {
  color: #fff;
  transform: rotate(180deg);
}
.onclick-dot4 i {
  color: #fff;
  transform: rotate(180deg);
}
.onclick-dot5 i {
  color: #fff;
  transform: rotate(180deg);
}
.onclick-dot6 i {
  color: #fff;
  transform: rotate(180deg);
}
.onclick-dot7 i {
  color: #fff;
  transform: rotate(180deg);
}
.bg-red {
  height: 40px;
  width: 40px;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.onclick-dot1 {
  position: absolute;
  top: 52%;
  transform: translateY(-100%);
  left: 68%;
}
.onclick-dot2 {
  position: absolute;
  top: 66%;
  transform: translateY(-100%);
  right: 36%;
}
.onclick-dot6 {
  position: absolute;
  top: 53%;
  transform: translateY(-100%);
  left: 29%;
}
.onclick-dot3 {
  position: absolute;
  top: 22%;
  transform: translateY(-100%);
  right: 58%;
}
.onclick-dot4 {
  position: absolute;
  top: 53%;
  transform: translateY(-100%);
  left: 8%;
}
.onclick-dot5 {
  position: absolute;
  top: 53%;
  transform: translateY(-100%);
  right: 41%;
}
.onclick-dot7 {
  position: absolute;
  bottom: -3%;
  transform: translateY(-100%);
  left: 27%;
}
.safe-info4 {
  position: absolute;
  top: 26%;
  background: #fff;
  width: 43%;
  padding: 16px;
  left: 7%;
  border-bottom: 3px solid var(--primary);
  height: 189px;
  overflow: auto;
}
.safe-info5 {
  position: absolute;
  top: 5%;
  background: #fff;
  width: 43%;
  padding: 16px;
  left: 50%;
  border-bottom: 3px solid var(--primary);
  height: 189px;
  overflow: auto;
}
.active-red {
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active-red i {
  color: var(--primary);
}
.intro .container-xxl {
    overflow-x: hidden;
  }
  .human .container-xxl {
    overflow-x: hidden;
  }