.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 5400;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 10px 0px;
}
.accordion-header {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 0px !important;
}
.introdu h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.comp-bg {
  background-image: url(images/dra-road.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.comp-bg .overlay-bg {
  background-color: #32307062;
}
.comp-bg .overlay-bg h6 {
  font-size: 32px;
  line-height: 48px;
  color: #fff;
  font-weight: 600;
}
.border-top {
  width: 100px;
  height: 5px;
  background-color: white;
}
.introdu ul li::marker {
  color: var(--primary);
}
.comp-bg1 {
  background-image: url(images/drav-work.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}
.comp-bg1 .overlay-bg {
  background-color: #32307062;
}
.comp-bg1 .overlay-bg h6 {
  font-size: 32px;
  line-height: 48px;
  color: #fff;
  font-weight: 600;
}
.comp-bg1 .overlay-bg .comp-bg-text {
  height: 216px;
}
button.btn.btn-secondary {
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
button.btn.btn-secondary i {
  font-size: 14px;
}

.card__face {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  font-weight: 400;
  backface-visibility: hidden;
  padding: 0px 10px;
}
.card__face--front {
  border-top: 2px solid var(--primary);
}
ul li p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 5400;
  color: black;
}
.ss1-btn p {
  color: #fff;
}
.silder-box2 .steps a {
  background: var(--primary);
  width: fit-content;
  padding: 5px 30px;
  border-radius: 10px;
  display: block;
  font-size: 20px;
  font-weight: 800;
  color: white;
}
.silder-box2 .steps {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.padding-p {
  padding: 0px 131px;
}
.carousel-inner1 {
  width: 458px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .carousel-inner1 {
    width: 100% !important;
    margin: 0 auto;
  }
  .order-md-6 {
    order: 6 !important;
  }
  .order-md-7 {
    order: 7 !important;
  }
  .order-md-9 {
    order:8 !important;
  }
  .order-md-8{
    order: 9 !important;
  }
}
.comp-bg2 {
  height: 300px;
}
.comp-bg2 img {
  object-fit: cover;
  height: 100%;
}
.intro .container-xxl {
  overflow-x: hidden;
}
.human .container-xxl {
  overflow-x: hidden;
}
.order-md-6 {
  order: 6;
}
.order-md-7 {
  order: 7;
}
.order-md-9 {
  order: 9;
}
.order-md-8{
  order: 8;
}
