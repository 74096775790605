.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu h2 {
  font-size: 32px;
  line-height: 45px;
  font-weight: 400;
  color: black;
  padding: 10px 0px;
}
.accordion-header {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 0px !important;
}
.introdu h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.carousel-item .silder-box1 .ss1-btn {
  background-color: var(--primary);
  padding: 6px 50px !important;
  border-radius: 30px;
}
.carousel-item .ss1-btn p {
  color: #fff;
}
.carousel-item .silder-box2 .steps a {
  background: var(--primary);
  width: fit-content;
  padding: 5px 30px;
  border-radius: 10px;
  display: block;
  font-size: 20px;
  font-weight: 800;
  color: white;
}
.carousel-item .silder-box2 .steps {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.introdu ul li::marker {
  color: var(--primary);
}
.intro .container-xxl {
  overflow-x: hidden;
}
.human .container-xxl {
  overflow-x: hidden;
}
