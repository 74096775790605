.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu p span {
  font-size: 12px;
  font-weight: 500;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
}

.introdu h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 600;
  color: #fff;
  padding: 10px 0px;
}
.introdu ul li::marker {
  color: var(--primary);
}
.introdu h6 {
  font-size: 29px;
  font-weight: 400;
  line-height: 41px;
}

.introdu .no {
  width: 40px;
  height: 40px;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.introdu .no p {
  color: #fff;
}
.intro .container-xxl {
  overflow-x: hidden;
}
.human .container-xxl {
  overflow-x: hidden;
}
.nav-tabs .nav-link.active {
  background: #fff !important;
  display: block;
}
.silder-box1 .ss1-btn {
  background-color: var(--primary);
  padding: 6px 50px !important;
  border-radius: 30px;
}
.ss1-btn p {
  color: #fff;
}
.silder-box2 .steps a {
  background: var(--primary);
  width: fit-content;
  padding: 5px 30px;
  border-radius: 10px;
  display: block;
  font-size: 20px;
  font-weight: 800;
  color: white;
}
.silder-box2 .steps {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.pyramid {
  background: url(images/tri.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 50%;
}
.pyramid-box1:hover .hover-hide {
  display: block;
}
.pyramid-box2:hover .hover-hide1 {
  display: block;
}
.pyramid-box3:hover .hover-hide2 {
  display: block;
}
.pyramid-box4:hover .hover-hide3 {
  display: block;
}
.pyramid-box5:hover .hover-hide4 {
  display: block;
}

.pyramid-box1 .hover-hide {
  display: none;
  position: absolute;
  top: 0;
  right: 55px;
  width: 50%;
  background-color: white;
  color: #000;
}
.pyramid-box2 .hover-hide1 {
  display: none;
  position: absolute;
  top: 20%;
  right: 55px;
  width: 50%;
  background-color: white;
  color: #000;
}
.pyramid-box3 .hover-hide2 {
  display: none;
  position: absolute;
  top: 40%;
  right: 55px;
  width: 50%;
  background-color: white;
  color: #000;
}
.pyramid-box4 .hover-hide3 {
  display: none;
  position: absolute;
  top: 50%;
  right: 55px;
  width: 50%;
  background-color: white;
  color: #000;
}
.pyramid-box5 .hover-hide4 {
  display: none;
  position: absolute;
  top: 70%;
  right: 55px;
  width: 50%;
  background-color: white;
  color: #000;
}
.silder-img {
  width: 50%;
}
@media (max-width: 768px) {
  .silder-img {
    width: 100%;
  }
  .pyramid {
    width: 100% !important;
  }
  .pyramid-box1 .hover-hide {
    top: 0% !important;
    width: 100%;
    right: 0;
  }
  .pyramid-box2 .hover-hide1 {
    top: 0% !important;
    width: 100%;
    right: 0;
  }
  .pyramid-box3 .hover-hide2 {
    top: 0% !important;
    width: 100%;
    right: 0;
  }
  .pyramid-box4 .hover-hide3 {
    top: 0% !important;
    width: 100%;
    right: 0;
  }
  .pyramid-box5 .hover-hide4 {
    top: 0% !important;
    width: 100%;
    right: 0;
  }
}
.pyramid-box1 p {
  background-color: white;

  padding: 2px 10px;
  cursor: pointer;
}
.pyramid-box2 p {
  background-color: white;

  padding: 2px 10px;
  cursor: pointer;
}
.pyramid-box3 p {
  background-color: white;

  padding: 2px 10px;
  cursor: pointer;
}
.pyramid-box4 p {
  background-color: white;

  padding: 2px 10px;
  cursor: pointer;
}
.pyramid-box5 p {
  background-color: white;

  padding: 2px 10px;
  cursor: pointer;
}
.pyramid-box1 {
  height: 80px;
  font-size: 18px;
  font-weight: 500;
  color: white;
}
.pyramid-box2 {
  height: 80px;
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.pyramid-box3 {
  height: 80px;
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.pyramid-box4 {
  height: 80px;
  font-size: 18px;
  font-weight: 500;
  color: white;
}
.pyramid-box5 {
  height: 80px;
  font-size: 18px;
  font-weight: 500;
  color: white;
}

#set {
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 18px;

  font-weight: 500;
  color: #000;
}
#met {
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
#pract {
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
#guide {
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
