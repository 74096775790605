.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu h2 {
  font-size: 32px;
  line-height: 45px;
  font-weight: 400;
  color: black;
  padding: 10px 0px;
}
.accordion-header {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 0px !important;
}
.introdu h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.introdu h6 {
  font-size: 32px;
  line-height: 48px;
  color: #fff;
  font-weight: 600;
}
.introdu ul li::marker {
  color: var(--primary);
}
.nav-tabs .nav-link.active {
  background: #fff !important;
  display: block;
}
.comp-bg {
  background-image: url(images/first-aid/images1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 300px;
 
}
.comp-bg .overlay-bg {
  background-color: #000000c4;
  display: flex;
 
  align-items: center;
}
.comp-bg .overlay-bg h6 {
  font-size: 32px;
  line-height: 48px;
  color: #fff;
  font-weight: 600;
}
.border-top {
  width: 100px;
  height: 5px;
  background-color: white;
}
table.customTable {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 3px;
  border-color: #ffffff;
  border-style: solid;
  color: #000000;
}

table.customTable td,
table.customTable th {
  border-width: 1px;
  border-color: #ddd;
  border-style: solid;
  padding: 20px 20px;

  text-align: center;
}

table.customTable thead {
  background-color: var(--primary);
}
.intro .container-xxl {
  overflow-x: hidden;
}
.human .container-xxl {
  overflow-x: hidden;
}