.intro p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
#legal h5 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
#legal ul li::marker {
  color: var(--primary);
}
.matches .match ul li {
  list-style: none;
}
.matches .match ul li svg {
  fill: white;
  stroke: #ddd;
  width: 100%;
}
.matches .match ul li svg rect {
  fill: black;
}

.matches .match ul li .match-content i {
  color: #000;
}
.matches {
  background: #fff;
  border: 1px solid #ddd;
  box-sizing: border-box;
  box-shadow: 0 0.4rem 1.2rem 0.2rem rgba(0, 0, 0, 0.05);
}
.matches a.sub-btn {
  background: var(--primary);
  display: inline-block;
  padding: 4px 40px;
  border-radius: 32px;
}
.matches a.sub-btn p {
  color: white;
}
.matches .thanks .fail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #cacbcb;
}
.matches .thanks .fail i {
  font-size: 20px;
}
/* .exting ul li {
  list-style: none;
  width: 40px;
  background: var(--primary);
  border-radius: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 2px solid transparent;
}
.exting ul li i {
  font-size: 20px;
}
.exting ul li:hover {
  border: 2px solid white;
}
.exting ul {
  position: absolute;
  width: 83%;
  top: 55%;
  left: 6%;
  right: 0;
} */
.escape span {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #000;
}
/* .acc-img1 {
  width: 50%;
} */
.silder-box2 .steps a {
  background: var(--primary);
  width: fit-content;
  padding: 5px 30px;
  border-radius: 10px;
  display: block;
  font-size: 20px;
  font-weight: 800;
  color: white;
}
.silder-box2 .steps {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.thanks-you p {
  font-size: 22px;
  line-height: 33px;
  color: #fff;
  padding: 0px 90px;
}
.thanks-you {
  background-color: var(--primary);
}
.thanks-you h5 {
  color: #fff !important;
}
.form-check-input {
  border: 1px solid #383838;
}
.slick-track{
  display: flex !important;
  justify-content: center;
  align-items: center;
}