.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 5400;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 10px 0px;
}
.accordion-header {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 0px !important;
}
.introdu h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.comp-bg {
  background-image: url(images/compbg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 647px;
}
.comp-bg .overlay-bg {
  background-color: #c62026bd;
}
.comp-bg .overlay-bg h6 {
  font-size: 32px;
  line-height: 48px;
  color: #fff;
  font-weight: 600;
}
.border-top {
  width: 100px;
  height: 5px;
  background-color: white;
}
.introdu ul li::marker {
  color: var(--primary);
}
.acc-img1 {
  height: 400px;
}
.nav-tabs .nav-link.active {
  background: #fff !important;
  display: block;
}
.acc-img1 img {
  height: 100%;
  object-fit: contain;
}
.intro .container-xxl {
  overflow-x: hidden;
}
.human .container-xxl {
  overflow-x: hidden;
}
@media (max-width: 600px) {
  .comp-bg .overlay-bg h6 {
    font-size: 22px;
    line-height: 38px;
    color: #fff;
    font-weight: 600;
  }
}
@media (max-width: 1300px) {
  .comp-bg .overlay-bg h6 {
    font-size: 25px;
    line-height: 40px;
    color: #fff;
    font-weight: 600;
  }
}
