@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,100&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  line-height: normal;
  overflow-x: hidden;
  height: 100vh;
  font-family: "Roboto", sans-serif !important;
}

@media (max-width: 1200px) {
  .table-main {
    width: 1200px;
    overflow-x: auto;
  }
}
.look {
  display: flex;
  flex-direction: column;
  height: 100%;
}
footer {
  margin-top: auto;
}
.mybg-primary {
  background: #cc202e;
}

.mytext-primary {
  color: #cc202e;
}

.mybg-secondary {
  background: #2b3766;
}

.mytext-secondary {
  color: #2b3766;
}

.d-grid-center {
  display: grid;
  place-items: center;
}

header {
  margin: 0;
  padding: 8px 0px;
  background: #fff;
  position: sticky !important;
  top: 0;
}
header .logo-top img {
  width: 100%;
}
header .navbar-list button {
  color: #000;
  font-size: 16px;
}
header .navbar-list a {
  color: #000;
  font-size: 16px;
}
header .navbar-list .active {
  color: #cc202e;
}

.sticky {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  right: 0;
  background: #fff;
  animation: mymove 1s ease-in-out;
}

@keyframes mymove {
  from {
    top: -50px;
  }
  to {
    top: 0px;
  }
}
.fullscreen {
  padding: 60px 0;
  background: url(../images/bg.jpg) no-repeat center;
  background-size: cover;
}
.fullscreenre {
  padding: 60px 0;
  background: url(../images/bg.jpg) no-repeat center;
  background-size: cover;
}
.fullscreenadmin {
  padding: 60px 0;
  background: url(../images/bg11.jpg) no-repeat center;
  background-size: cover;
}

.form-box textarea,
.form-box select,
.form-box input[type="text"],
.form-box input[type="date"],
.form-box input[type="number"],
.form-box input[type="password"],
.form-box input[type="email"],
.form-box input[type="tel"] {
  font-size: 14px;
  border-radius: 10px !important;
  border: none;
  color: #000;
  padding: 10px 10px 10px 40px;
  width: 100%;
  border: 1px solid transparent;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.no-lgap textarea,
.no-lgap select,
.no-lgap input[type="text"],
.no-lgap input[type="date"],
.no-lgap input[type="number"],
.no-lgap input[type="password"],
.no-lgap input[type="email"],
.no-lgap input[type="tel"] {
  padding-left: 10px;
}
.form-box input[type="text"]:focus,
.form-box input[type="password"]:focus,
.form-box input[type="email"]:focus,
.form-box input[type="tel"]:focus {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.form-box .top-icon {
  position: absolute;
  z-index: 4;
  top: 13px;
  font-size: 14px;
  left: 10px;
  color: gray;
}

.forgot-pass {
  font-size: 14px;
  font-weight: 600;
  color: #cc202e;
}

.submit-btn {
  background: #cc202e;
  color: #fff;
  font-size: 14px;
  border-radius: 6px !important;
  padding: 10px;
  border: none;
  width: 100%;
  text-align: center;
}
.submit-btn:hover {
  background: #b61d29;
}

.reg-box {
  background: rgba(43, 55, 102, 0.7);
}

.btn-red-block a {
  background: #cc202e;
  color: #fff;
  font-size: 16px;
  padding: 8px 30px;
  width: 100%;
  text-align: center;
  display: block;
}
.btn-red-block a:hover {
  background: #a01924;
  color: #fff;
}

.innerbanner {
  background: #cc202e;
}

.pic-emp {
  margin-top: -50px;
}

.emplo-box-pic {
  width: 100px;
  height: 100px;
  border-radius: 100% !important;
  overflow: hidden;
}

.client-btn-text {
  background: #ccc;
  color: #000;
  text-align: center;
  padding: 15px 10px;
}
.client-btn-text:hover {
  color: #000;
  background: #b3b3b3;
}

.client-btn-text.client-active {
  background: #e86a75;
}

.sikll-text-head {
  background: #ccc;
  color: #000;
  text-align: center;
  font-size: 14px;
  padding: 10px;
}
.sikll-text-head:after {
  position: absolute;
  content: "";
  content: ",";
}

.scroll-date-block {
  overflow-x: auto;
  width: 320px;
  padding: 10px 0;
}
@media only screen and (max-width: 767.98px) {
  header .navbar-list a {
    font-size: 12px;
  }
}
@media only screen and (max-width: 767.98px) {
  .scroll-date-block {
    width: 170px;
  }
}
.scroll-date-block h5 {
  font-size: 14px;
  color: #000;
  position: relative;
}
.scroll-date-block h5:after {
  position: absolute;
  content: "";
  content: ",";
}

.user-icon-top {
  border: 1px solid #999999;
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius: 100% !important;
} /*# sourceMappingURL=main.css.map */

.logout {
  background: gray;
  color: #fff !important;
  padding: 10px;
  border-radius: 4px;
}

.formbox label {
  float: left;
}

.custom-date-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.special-icon {
  font-size: 24px; /* Adjust the icon size as needed */
  color: #ff0000; /* Change the icon color */
}

.special-title {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}
.ft-topbox {
  background: #111;
}
.ft-boxes h3 {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
}
.ft-links p,
.ft-links p a {
  color: #fff;
  font-size: 14px;
}
.copy-ft {
  background: #fff;
}
.copy-ft p {
  color: #111;
  font-size: 16px;
}
section.innerbanner.text-center.py-5.d-block.bgcolor {
  background: #18147d;
}
header._br_-q {
  align-items: center;
}
.p-3.mb-2.bg-secondary.rounded-circle.text-white.coursename {
  width: 55px;
  height: 51px;
  /* margin: auto; */
  text-align: center;
  line-height: 16px;
}
span.mt-3.ml-2.textcourse {
  margin-left: 15px;
}
tr.border-2.tablecourse {
  background: #ce9999;
  border: 1px solid #ce9999;
}
a.unassign {
  border: 1px solid #ff3b3b;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #ff3b3b;
}
.table_responsive {
  height: fit-content;
  overflow-y: auto;
}
.coursediv.col-md-12 {
  height: fit-content;
  overflow-y: scroll;
}

.w-fit-content {
  width: fit-content;
}
.folder-onboarding p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
}
.fullscreen-onboarding {
  padding: 60px 0;
  background: url(../images/s1.JPG) no-repeat center;
  background-size: cover;
}
.fullscreen-onboarding-file {
  background: #ebf3fa;
  background-size: cover;
}
.fullscreen-onboarding-file .upload p {
  font-size: 20px;
  color: #000;
  font-weight: 500;
}
.fullscreen-onboarding-file input.files {
  position: absolute;
  top: 0;
  padding: 100px;
  border: 1px solid red;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
}
.fullscreen-onboarding-file .border-file {
  border-top: 5px solid #000 !important;
  background-color: transparent;
  width: 100%;
}
.fullscreen-onboarding-file form input {
  padding: 10px 10px;
  border-radius: 8px;
  border: none;
  background-color: #d9d9d9;
}
.fullscreen-onboarding-file form .date input {
  width: 80px;
}
.fullscreen-onboarding-file form .date {
  width: 51%;
}
.fullscreen-onboarding-file form .upload-btn {
  background-color: #f71701;
  padding: 5px 23px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}
.fullscreen-onboarding-file form input.upload-btn-file {
  position: absolute;
  width: 100%;
  top: 0;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}

.fullscreen-onboarding-file .sub-btn-file {
  button {
    background-color: red;
    border-radius: 10px;
    padding: 5px 23px;
    color: #fff;
    border: none;
  }
}
.fullscreen-onboarding-file .aad-item-file {
  color: red;
  font-size: 16px;
  font-weight: 500;
}
.fullscreen-onboarding-file form .submit-btn-files button {
  background-color: #f71701;
  padding: 5px 23px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  border: none;
}
.well-info {
  position: absolute;
  top: 55px;
  background: white;
  right: 50%;
  transform: translateX(50%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.well-info ul {
  list-style: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  overflow: auto;
  height: 171px;
}
.well-info ul li {
  width: 100%;
  padding: 20px 14px;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.well-info ul li a {
  font-size: 12px;
  color: #000;
}
.well-info a {
  font-size: 12px !important;
  color: #000;
}
.cursor-pointer {
  cursor: pointer;
}
.well-info button {
  background-color: #cc202e;
  padding: 7px 20px;
  color: #fff !important;
  border: none;
  font-size: 12px !important;
  color: #000;
}
.Document-nm {
  input {
    position: absolute;
    top: 0;
    left: 34px;
    transform: translateX(-50%);
    width: 72px;
    opacity: 0;
  }
  button {
    border: none;
    background-color: #ff0000;
    color: #fff !important;
    font-size: 14px !important;
    padding: 4px 30px;
  }
}
.Document-nm1 {
  input {
    position: absolute;
    top: 0;
    left: 34px;
    transform: translateX(-50%);
    width: 72px;
    opacity: 0;
  }
  button {
    border: none;
    background-color: #ff0000;
    color: #fff !important;
    font-size: 14px !important;
    padding: 4px 30px;
  }
}
.form-check .form-check-input {
  margin: 0px !important;
}
span.notcount {
  position: absolute;
  top: 8px;
  left: 12px;
  right: 0;
}
.imgcount {
  position: relative;
}
span.textlic {
  background: white;
  float: right;
  font-size: 16px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  padding: 3px;
  color: #f73b3c;
  font-weight: 900;
}
button.docup {
  opacity: 0.1;
  pointer-events: none;
}
.bgg {
  ul {
    position: absolute;
    width: 142px;
    right: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: #b5b5b5;
    padding: 9px;
    top: 46px;
    li {
      list-style: none;
      a {
        color: #000;
      }
    }
  }
}
@media (max-width: 768px) {
  .well-info {
    position: absolute;
    top: 43px;
    background: white;
    right: 66px;
    transform: translateX(50%);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }
}
@media (max-width: 1200px) {
  .well-info {
    top: 44px;
  }
}
