@import "../css/all.min.css";
input,
textarea,
select,
ol,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-decoration: none;
  outline: none;
}
a,
a:hover,
a:focus,
*:focus-visible {
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-decoration: none;
  outline: none;
}
button:focus {
  outline: none;
  border: none;
}
button:focus,
input:focus {
  box-shadow: none !important;
}
button {
  cursor: pointer;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ls li {
  list-style: none;
}
.cur {
  cursor: pointer;
}
.po img {
  opacity: 0;
}
.vclip {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.maxw img {
  max-width: 100%;
}
.g-center {
  place-items: center;
  display: grid;
}
.fulw img {
  width: 100%;
}

.rel {
  position: relative;
}
.ofit img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.mac-select {
  position: relative;
}
.mac-select select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border: none;
  background: #fff;
}
.mac-select:after {
  content: "";
  z-index: 3;
  top: 54%;
  right: 8px;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #000000;
  border-width: 5px;
  margin-left: -10px;
  transform: translateY(-50%);
}
a.addclient {
  color: #fff;
  background: red;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
a.editclient {
  color: #fff;
  background: green;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
a.addclientapp {
  color: #fff;
  background: green;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 10px;
}
button.addclientappfile {
  color: #EF3B3B;
  border-radius: 4px;
  border: 1px solid #fff;
  font-size: 35px;
  margin-bottom: 10px;
}
textarea.addany {
  width: 765px;
  height: 200px;
  border: none;
}
.d-flex.flex-column.gap-4.heightprofile {
  height: 220px;
  overflow-y: scroll;
  overflow-x: hidden;
}



.new-con-block {
  background: #eee;
}
