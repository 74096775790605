@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  padding: 0;
  background: #fafafa;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif !important;
}

.d-grid-center {
  display: grid;
  place-items: center;
}

header {
  margin: 0;
  position: fixed;
  top: 0;
  z-index: 77;
  left: 0;
  right: 0;
  padding: 6px 0px;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
}
header .logo-box {
  width: 140px;
}
header .top-links {
  color: #000;
  font-size: 13px;
  padding: 4px 10px;
}

.user-box button {
  background: #fff;
  border: none;
}
.user-box button .user-admin-img {
  width: 24px;
  height: 24px;
  aspect-ratio: 1;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 100% !important;
}
.user-box button .user-text-admin {
  font-size: 12px;
  color: #000;
  font-weight: 300;
}
.user-box .drop-user-links {
  position: absolute;
  background: whitesmoke;
  box-shadow: 2px 3px 4px #cccccc;
  left: 0;
  min-width: 130px;
}
.user-box .drop-user-links a {
  color: #000;
  font-size: 13px;
  padding: 6px 10px;
  display: block;
}
.user-box .drop-user-links a:hover {
  color: #2a2979;
}
/* .user-box:hover .drop-user-links {
  display: block;
} */

.navbar-top {
  position: fixed;
  border-right: 1px solid #cccccc;
  top: 72px;
  bottom: 0;
  left: 0;
  width: 240px;
  z-index: 44;
  background: #fff;
}

/* @media only screen and (min-width: 768px), (max-width: 1920px) {
  .navbar-top {
    display: block;
  }
} */

.h-85vh {
  height: 80vh !important;
}
.w-50 {
  width: 50% !important;
}
@media (max-width: 850px) {
  .h-85vh {
    height: 100% !important;
  }
  .w-50 {
    width: 90% !important;
    margin: 0 auto;
  }
}
.navbar-top .nav-link-main a {
  font-size: 13px;
  display: block;
  background: #2a29791a;
  color: #2a2979;
  padding: 8px 15px;
  margin-bottom: 1px;
}
.navbar-top .nav-link-main .active {
  background: #2a2979;
  color: #fff;
}
.navbar-top .nav-link-main a:hover {
  background: #2a2979;
  color: #fff;
}

.nav-mob button {
  background: #2a2979;
  color: #fff;
  border: none;
  width: 30px;
  text-align: center;
}
.logo-top {
  width: 100px !important;
}

.main-right-body {
  margin-left: 240px;
  display: block;
  padding: 30px;
}
@media only screen and (max-width: 767.98px) {
  .main-right-body {
    margin-left: 0px;
    padding: 15px;
  }
}

.red-heading h3 {
  color: #2a2979;
  font-size: 14px;
  font-weight: 500;
}

.over-empl {
  background: #fff;
  overflow-y: auto;
  height: fit-content;
  display: block;
}
.over-empl a {
  background: #f7f7f7;
  color: #999999;
  font-size: 14px;
  padding: 8px 10px;
  display: block;
  margin-bottom: 1px;
}
.over-emplcc {
  background: #fff;
  overflow-y: auto;
  height: 300px;
  display: block;
}
.over-admin {
  background: #fff;
  overflow-y: auto;
  display: block;
}
.over-admin a {
  background: #f7f7f7;
  color: #999999;
  font-size: 14px;
  padding: 8px 10px;
  display: block;
  margin-bottom: 1px;
}
.over-emplcc a {
  background: #f7f7f7;
  color: #999999;
  font-size: 14px;
  padding: 8px 10px;
  display: block;
  margin-bottom: 1px;
}
.over-empls {
  background: #fff;
  overflow-y: auto;
  height: fit-content;
  display: block;
}
.over-empls a {
  background: #f7f7f7;
  color: #999999;
  font-size: 14px;
  padding: 8px 10px;
  display: block;
  margin-bottom: 1px;
}

.right-form-box label {
  font-size: 12px;
  color: #000;
  font-weight: 600;
}
.right-form-box input[type="text"],
.right-form-box input[type="date"],
.right-form-box input[type="radio"],
.right-form-box input[type="email"],
.right-form-box input[type="tel"],
.right-form-box input[type="date"],
.right-form-box select,
.right-form-box textarea {
  font-size: 13px;
  background: #fff;
  color: #000;
  border: 1px solid #cccccc;
  padding: 6px 10px;
  width: 100%;
}
.no-lgap textarea,
.no-lgap select,
.no-lgap input[type="text"],
.no-lgap input[type="date"],
.no-lgap input[type="number"],
.no-lgap input[type="password"],
.no-lgap input[type="email"],
.no-lgap input[type="tel"] {
  padding-left: 10px;
}
.sub-btn {
  background: #2a2979;
  color: #fff;
  font-size: 13px;
  border: none;
  padding: 6px 30px;
  text-transform: capitalize;
}
.sub-btn:hover {
  background: #cccccc;
  color: #2a2979;
}

.timesheet-boxes h6 {
  color: #000;
  font-size: 12px;
  font-weight: 600;
}
.timesheet-boxes p {
  color: #999;
  font-size: 12px;
  font-weight: 300;
}
.btn-rosters h3 {
  color: #2a2979;
  font-weight: 600;
  font-size: 24px;
}
.client-red-roster button {
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px 30px;
}
.client-red-roster button:hover {
  background: #2a2979;
  color: #000;
}
.scroll-date-block {
  overflow-x: auto;
  width: 320px;
  padding: 10px 0;
}
.scroll-date-block h5::after {
  position: absolute;
  content: ",";
}
.scroll-date-block h5 {
  font-size: 14px;
  color: #000;
  position: relative;
}
.elm-shift-box h4 {
  font-size: 16px;
}
.elm-shift-box h3 {
  font-size: 16px;
}
.year-text-block {
  font-size: 14px;
}
@media only screen and (max-width: 767.98px) {
  .scroll-date-block {
    width: 151px;
  }
  .elm-shift-box h4 {
    font-size: 14px;
  }
}
h6.timesheettemp {
  margin-bottom: 7px;
}
td.bg_yellow {
  background: yellow;
}
.innerradio {
  height: fit-content;
  padding: 5px;
  background: #fff;
}
.roast-sel {
  background: #a3a3a347 !important;
  height: fit-content !important;
  padding: 15px 0;
}

.innerradio input[type="radio"] {
  display: none;
}
.innerradio label {
  display: inline-block;
  cursor: pointer;
}
.innerradio input[type="radio"]:checked + label {
  background-color: #76cf9f;
}
.weather-top-icon {
  color: #fff;
  background: #2a2979;
  display: grid;
  width: 30px;
  height: 30px;
  place-items: center;
  border-radius: 100px;
}
.weather-top-icon.active {
  background: #000;
  color: #fff;
}
.flex-shrink-0.icon-user-we.bgfip {
  background: #7dbffc;
}
.flex-shrink-0.icon-user-we.foa {
  background: #9f20aa;
}
.flex-shrink-0.icon-user-we.sl {
  background: #395aef;
}
.flex-shrink-0.icon-user-we.ds {
  background: #b2daf2;
}
.flex-shrink-0.icon-user-we.fia {
  background: #b9723d;
}
.flex-shrink-0.icon-user-we.iso {
  background: #fb02f5;
}
.flex-shrink-0.icon-user-we.lwp {
  background: #f3dc0c;
}
.flex-shrink-0.icon-user-we.ns {
  background: #1d0303;
}
.flex-shrink-0.icon-user-we.fop {
  background: #77d928;
}
.flex-shrink-0.icon-user-we.wo {
  background: #490909;
}
.list-weather .icon-user-we {
  color: #fff;
  background: #f71718;
  display: grid;
  width: 30px;
  height: 30px;
  place-items: center;
  border-radius: 100px;
}
.list-we-name h5 {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.innerradio {
  display: block;
  position: relative;
  padding: 4px 10px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.innerradio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  /* left: 0; */
  /* height: 25px; */
  /* width: 25px; */
  background-color: #fff;
  /* border-radius: 50%; */
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.innerradio label p {
  position: relative;
  z-index: 2;
  font-size: 14px;
  text-align: center;
}

/* On mouse-over, add a grey background color */
.innerradio:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.innerradio input:checked ~ .checkmark {
  background-color: red;
  color: #fff;
}
.innerradio input:checked ~ p {
  color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.innerradio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
/* .innerradio .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
} */
.clientselectmain a {
  box-shadow: 0px 0px 3px 0px #00000028;
  border-radius: 5px;
  border: 2px solid transparent;
}
.red-heading {
  max-height: 80vh;
  overflow-y: auto;
}
.clientselectmain a.active {
  border: 2px solid #ff0000;
  border-radius: 5px;
  box-shadow: 0px 0px 0px #000;
  color: #000;
}
.minestone a.active {
  background: #ff0000;
  color: #fff;
}

.ReactModal__Content {
  max-width: 400px;
  height: auto;
  margin: auto;
  z-index: 9999;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgb(0 0 0 / 43%) !important;
  z-index: 99;
}

/* profile css */
.innerbanner {
  background: #cc202e;
}
.pic-emp {
  margin-top: -50px;
}
.emplo-box-pic {
  width: 100px;
  height: 100px;
  border-radius: 100% !important;
  overflow: hidden;
}
.client-btn-text {
  background: #ccc;
  color: #000;
  text-align: center;
  padding: 15px 10px;
}
.client-btn-text:hover {
  background: #cc202e;
  color: #fff;
}
.client-btn-text.client-active {
  background: #cc202e;
  color: #fff;
}
.subbtn {
  background: #cc202e;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 30px;
  border: none;
}
span.currentroster {
  background: red;
  padding: 6px;
  color: white;
}
.signimg {
  background: url(../images/sign.png) no-repeat right bottom;
  background-size: 30px;
}
.myytext-primary {
  color: green;
}
img.signsheet {
  width: 65px;
}
.ReactModal__Content.profile {
  /* height: 550px !important; */
  border-radius: 5px;
}
.ReactModal__Content.role {
  height: 250px !important;
  border-radius: 5px;
}
.ReactModal__Content.time_sheetmodal {
  max-width: 700px;
}
.week-month-day-buttons .rbc-btn-group {
  display: none !important;
}
p.user-text-icon {
  color: #000;
  background: #ccc;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  text-align: center;
  font-size: 14px;
  line-height: 43px;
  border-radius: 100px;
}
.scroll-text-box {
  /* border: 1px solid red; */
  /* height: 500px; */
  overflow-y: auto;
}
button.refreshbutton {
  color: antiquewhite;
  border-radius: 3px;
  width: 40px;
  padding: 5px;
  background: #0d6efd;
  border: 1px solid #0d6efd;
}
img.imgsrc {
  width: 30px;
  float: right;
  margin-left: 15px;
}
.form-scroll-profile {
  height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
}
.over-empl.heit {
  height: fit-content;
}
input.serch {
  padding: 9px !important;
  border-radius: 4px;
}
.block-full {
  margin-top: 10px;
}
button.sub-btn.rounded.p-2.step1 {
  opacity: 0.1;
  pointer-events: none;
}

.pdf_time h1 {
  font-size: 35px;
  font-weight: 500;
  color: #000;
}
.pdf_time h4 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.pdf_time h4 span {
  opacity: 0.8;
}
.flex-shrink-0.icon-user-we.sc {
  background: #0b5ed7;
}
@media (max-width: 768px) {
  .pdf_time .table_component {
    width: 800px !important;
  }
}
@media only screen and (max-width: 768px) {
  p.user-text-icon {
    font-size: 12px;
  }
  .list-we-name h5 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767.98px) {
  .navbar-top {
    display: none;
  }
  .rbc-calendar * {
    font-size: 12px;
  }
}
@media only screen and (max-width: 500px) {
  .table-w {
    width: 270px;
    overflow-x: auto;
  }
}
.edit-margin {
  margin-top: 10px;
}
