.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu p span {
  font-size: 14px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu h2 {
  font-size: 32px;
  line-height: 45px;
  font-weight: 400;
  color: black;
  padding: 10px 0px;
}
.accordion-header {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
  padding: 0px !important;
}
.introdu h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.introdu h6 {
  font-size: 32px;
  line-height: 48px;
  color: #fff;
  font-weight: 600;
}
.introdu ul li::marker {
  color: var(--primary);
}
.small-size {
  width: 200px;
}
table.customTable {
    width: 100%;
    background-color: #ffffff;
    border-collapse: collapse;
    border-width: 3px;
    border-color: #ffffff;
    border-style: solid;
    color: #000000;
  }
  
  table.customTable td,
  table.customTable th {
    border-width: 1px;
    border-color: #ddd;
    border-style: solid;
    padding: 20px 20px;

  }
  
  table.customTable thead {
    background-color: var(--primary);
  }
  .intro .container-xxl {
    overflow-x: hidden;
  }
  .human .container-xxl {
    overflow-x: hidden;
  }