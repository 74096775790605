:root {
  --primary: #c62026;
  --secondary: #293867;
  --white: #fff;
  --black: #111;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}
header {
  display: block;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}
.main-red-block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 444;
  background: rgba(198, 32, 38, 0.7);
  padding: 15px 0;
}
.logo-block a {
  width: 120px;
}
.user-login-btn .reg-btn {
  color: var(--white);
  padding: 6px 22px;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.user-login-btn div:first-child .reg-btn {
  background: transparent;
}
.user-login-btn div:last-child .reg-btn {
  background: var(--primary);
}
.user-login-btn div a:hover {
  background: var(--secondary);
}
.drop-menu-block {
  background: rgba(0, 0, 0, 0.5);
  padding: 0;
  list-style: none;
  position: absolute;
  right: 0;
  min-width: 120px;
  display: none;
  flex-direction: column;
}
.drop-menu-block li a {
  padding: 4px 10px;
  display: block;
}
.drop-menu-block li:first-child a {
  background: var(--secondary);
  color: var(--white);
}
.drop-menu-block li:last-child a {
  background: var(--white);
  color: var(--secondary);
}

.user-login-btn .drop-block:hover .drop-menu-block {
  display: flex;
}
.main-slider,
.main-slider .carousel,
.main-slider .carousel div {
  height: 100vh;
  width: 100%;
}
.main-slider div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-slider .carousel .carousel-item:after {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
  z-index: 4;
}
.dicover-block {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 33;
  left: 0%;
  padding: 0 5%;
  max-width: 650px;
  width: 100%;
}
.dicover-block h1 {
  color: var(--white);
  font-size: 38px;
  font-weight: 800;
}
.top-search-box {
  background: var(--white);
}
.top-search-box input[type="text"],
.top-search-box input[type="search"] {
  width: 100%;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
}
.top-search-box button {
  background: var(--primary);
  color: var(--white);
  border: none;
  padding: 12px 20px;
}
.dicover-block .search-tags h4,
.dicover-block .search-tags a {
  color: var(--white);
  font-size: 16px;
}
.dicover-block .search-tags a:hover {
  text-decoration: underline;
}
.hlogos {
  background: #f1f1f1;
}
.main-heading {
  font-size: 34px;
  color: var(--black);
  font-weight: 800;
}
.img-block .img-pic {
  overflow: hidden;
}
.img-block .img-pic img {
  width: 100%;
}
.img-block .text-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px;
  color: var(--white);
}
.img-block .text-list h4 {
  font-size: 14px;
  font-weight: 400;
}
.img-block .text-list h5 {
  font-size: 24px;
  font-weight: 600;
}
.slick-arrow {
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
  background: var(--white);
  color: var(--secondary);
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  opacity: 0.8;
  border: 1px solid rgb(226, 226, 226);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}
.slick-arrow:hover {
  opacity: 1;
}
.slick-prev {
  left: -50px;
}
.slick-next {
  right: -50px;
}
.slick-dots li button {
  width: 10px;
  height: 10px;
  overflow: hidden;
  text-indent: -4000px;
}
.slick-dots .slick-active button {
  background: #2a3665;
}
.icon-pic-box {
  text-align: center;
  overflow: hidden;
  padding: 40px 20px;
  border: 1px solid rgb(226, 226, 226);
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
}
.icon-pic-box .icon-photo {
  width: 100px;
  height: 100px;
}
.icon-pic-box h3 {
  font-size: 22px;
  margin-top: 15px;
  color: var(--black);
}
.bg-left-gr {
  background: #ebf3fa;
}
.pop-top-heading h1 {
  color: var(--black);
  font-size: 32px;
  font-weight: 800;
}
.pop-top-heading p {
  color: var(--black);
  font-size: 18px;
}
.button-yellow button {
  background: #fdc708;
  color: var(--black);
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  font-weight: 500;
  width: 100%;
  padding: 8px 20px;
}
.have-acc p {
  color: var(--black);
  font-size: 16px;
}
.btns-reg button {
  border: none;
  width: 100%;
  padding: 9px 20px;
  background: rgb(236, 236, 236);
  border: 1px solid #ccc;
}
.btns-reg button h6 {
  font-size: 14px;
}
.btns-reg button .icon-brand-search {
  width: 20px;
}
.contin-form label {
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
}
.contin-form input[type="text"],
.contin-form input[type="password"] {
  font-size: 14px;
  padding: 8px 10px;
  border: 1px solid rgb(235, 235, 235);
}
.contine-btn-sub button {
  background: var(--black);
  color: var(--white);
  font-size: 14px;
  border: none;
  width: 100%;
  padding: 8px 20px;
}
.top-part-box p {
  font-size: 16px;
  color: var(--black);
}
.btm-text-label {
  color: rgb(172, 172, 172);
}
.btm-text-label {
  font-size: 12px;
}
.otp-box {
  width: 30px;
}
.otp-box input {
  width: 100%;
}
.img-check-box label {
  height: 200px;
  padding: 15px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
}
.img-check-box input[type="radio"] {
  opacity: 0;
}
.img-check-box :checked + label {
  border-color: #ddd;
}

.img-check-box :checked + label:before {
  content: "✓";
  background-color: rgb(10, 88, 255);
  transform: scale(1);
  color: var(--white);
  position: absolute;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 0;
  right: 0;
}

.img-check-box :checked + label img {
}
.img-check-box .img-user-ex {
  width: 120px;
}
.img-check-box label img {
  width: 100%;
}
.name-letter {
  background: #6e88e5;
  color: var(--white);
  font-size: 30px;
  width: 120px;
  margin: 0 auto;
  font-size: 60px;
  line-height: 120px;
  height: 120px;
  border-radius: 100px;
}
.skip-links h5 {
  color: var(--black);
  font-size: 14px;
}
.skip-links button {
  color: var(--black);
  font-size: 14px;
  border: none;
  padding: 6px 10px;
  background: #efeef0;
}
.logo-blue {
  width: 120px;
}
.menu-side-btn button {
  border: 1px solid #ccc;
  width: 35px;
  height: 35px;
  background: var(--white);
}
.rtop-btn button {
  border: none;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  color: var(--black);
  background: var(--white);
}
.user-name button {
  position: relative;
  background-color: #e66863;
  color: var(--white);
  font-size: 14px;
}
.user-name .green-notif {
  background: green;
  width: 9px;
  height: 9px;
  border: 2px solid var(--white);
  border-radius: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.search-box-top form {
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}
.search-box-top form input {
  border: none;
  font-size: 14px;
  padding: 6px 20px;
  width: 100%;
}
.search-box-top form button {
  background: var(--black);
  color: var(--white);
  border: none;
  width: 40px;
  height: 34px;
}
.navbar-top {
  border-top: 1px solid #ccc;
  height: fit-content;
  display: flex;
  gap: 10px;
}
.navbar-top a {
  color: #ccc;
  font-size: 14px;
  padding: 6px;
  display: inline-block;
}
.navbar-top a:hover {
  color: #999;
}
.page-heading {
  color: var(--black);
  font-size: 36px;
  font-weight: 600;
}
.post-image-block .user-pic {
  height: 190px;
  border-radius: 8px;
  overflow: hidden;
}
.post-image-block .heart-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  color: var(--white);
}
.post-image-block .heart-icon:hover {
  background: rgba(255, 25, 25, 0.3);
  color: var(--white);
}
.user-small-pic {
  width: 20px;
  height: 20px;
}
.user-heading-small h4 {
  font-size: 13px;
}
.level-text h5 {
  font-size: 13px;
  font-weight: 600;
}
.post-content-user h2 {
  font-size: 20px;
  color: var(--black);
  font-weight: 300;
}
.post-content-user h6 {
  font-size: 16px;
  color: var(--black);
  font-weight: 700;
}
.page-navbar button {
  background: var(--white);
  border: none;
  width: 30px;
  height: 30px;
}
.page-link {
  border-bottom: 2px solid transparent;
}
.page-link:hover {
  border-bottom: 2px solid var(--black);
}
.filter-select select {
  background: #e4e2e0;
  color: var(--black);
  font-size: 16px;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
}
.filter-select select:focus {
  background: #595959;
  color: var(--white);
}
.jobpost1 {
  border: 1px solid var(--secondary);
  border-radius: 10px;
  padding: 25px 20px;
}
.jobpost1 .job-title {
  font-size: 20px;
  color: var(--black);
  font-weight: 600;
}
.jobpost1 .job-flag {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  overflow: hidden;
  display: inline-grid;
}
.jobpost1 .flag-text h4 {
  font-size: 16px;
  color: var(--black);
  font-weight: 300;
}
.jobpost1 .option-btn {
  width: 30px;
  height: 30px;
  color: var(--black);
  border: none;
  background: var(--white);
}
.jobpost1 .tag-gray h3 {
  background: #f3f2f1;
  padding: 6px 10px;
  border-radius: 5px;
}
.jobpost1 .tag-gray h3 {
  color: rgb(99, 99, 99);
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
}
.jobpost1 .easily-apply {
  color: var(--secondary);
  font-size: 14px;
}
.jobpost1 .list-text li {
  font-size: 14px;
  color: #999;
}
.post-dats p {
  color: #999;
  font-size: 12px;
  font-weight: 400;
}
.post-dats .text-post-app {
  color: #be8f5d;
}
.view-jobs {
  border-top: 1px solid #ccc;
  padding-top: 10px;
  margin-top: 5px;
}
.view-jobs a {
  color: var(--secondary);
  font-size: 13px;
}
.hiring-top {
  background: #913469;
  color: var(--white);
  font-size: 13px;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 33;
  border-radius: 0 6px 0 6px;
}
.top-job-name h3 {
  font-size: 20px;
  color: var(--black);
  font-weight: 600;
}
.start-links a {
  color: var(--secondary);
  font-size: 14px;
}
.start-links .start-rating i {
  font-size: 16px;
  color: var(--black);
}
.top-job-name h4 {
  font-size: 14px;
  color: #999;
}
.apply-btn-red {
  background: var(--primary);
  color: var(--white);
  font-size: 14px;
  padding: 6px 30px;
  display: inline-block;
}
.apply-btn-red:hover {
  background: var(--black);
  color: var(--white);
}
.contect-job-details h3 {
  font-size: 16px;
  color: var(--black);
  font-weight: 700;
}
.contect-job-details ul {
  display: flex;
  flex-direction: column;
  gap: 5px 0;
}
.contect-job-details ul li {
  font-size: 14px;
  color: var(--black);
}
.find-job {
  background: var(--secondary);
}
.find-job .heading-text {
  color: var(--white);
  font-size: 36px;
}
.find-search-block label {
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
}
.find-search-block :is(input, select) {
  font-size: 14px;
  padding: 8px 10px;
  width: 100%;
  border: none;
  background: var(--white);
  border-radius: 4px;
  height: 40px;
}
.find-search-block button {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  font-size: 14px;
  height: 40px;
  border-radius: 4px;
  width: 100%;
  padding: 8px 10px;
}
.shear-btn {
  color: #ccc;
}
.logo-shear {
  width: 140px;
}
.comp-text-block h2 {
  color: var(--black);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
}
.comp-text-block h4 {
  font-size: 16px;
  font-weight: 300;
  color: var(--black);
}
.review-rating i {
  color: #d06134;
}
.review-rating h5 {
  font-size: 16px;
  font-weight: 300;
  color: var(--black);
}
.turck-review-text {
  color: var(--secondary);
  font-size: 16px;
  text-transform: capitalize;
  text-decoration: underline;
}
.dump-text-block i {
  width: 20px;
  text-align: center;
}
.dump-text-block h5 {
  font-size: 16px;
  color: var(--black);
  font-weight: 400;
}
.quick-apply h4 {
  font-size: 16px;
  color: var(--black);
}
.quick-apply .linkcompany {
  font-size: 16px;
  color: var(--black);
  text-decoration: underline;
}

.quick-apply .qbtn {
  background: var(--primary);
  border-radius: 6px;
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  padding: 10px 30px;
  display: inline-block;
  border: none;
}
.quick-apply .sbtn {
  background: #f8f6fd;
  border-radius: 6px;
  color: var(--secondary);
  font-size: 16px;
  font-weight: 400;
  padding: 10px 30px;
  display: inline-block;
  border: none;
}

.text-block-location h5 {
  font-size: 16px;
  font-weight: 400;
}
.text-block-location h5 b {
  text-transform: uppercase;
}

.text-editor-block h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
  text-decoration: uppercase;
}
.text-editor-block p {
  font-size: 16px;
  font-weight: 400;
  color: #858585;
}
.text-editor-block ul {
  display: block;
}
.text-editor-block li {
  font-size: 16px;
  font-weight: 400;
  color: #858585;
}

.text-apply-now h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
  text-decoration: uppercase;
}
.text-apply-now p {
  font-size: 16px;
  font-weight: 400;
  color: #858585;
}
.text-apply-now ul {
  display: block;
}
.text-apply-now li {
  font-size: 16px;
  font-weight: 400;
  color: #858585;
}
.user-name-pic {
  background: var(--secondary);
  color: var(--white);
  aspect-ratio: 1;
  width: 70px;
  border-radius: 100px;
}
.user-name-pic h6 {
  font-size: 50px;
  line-height: 70px;
}
.user-dis-name h3 {
  font-size: 16px;
  color: var(--secondary);
}
.user-dis-name button {
  font-size: 16px;
  border: none;
  background: none;
  color: var(----secondary);
}
.user-dis-name span {
  font-size: 10px;
  background: var(--primary);
  color: var(--white);
  border-radius: 100px;
  padding: 2px 10px;
}
.stutas-user {
  position: absolute;
  border: 1px solid #5abc7a;
  border-radius: 100px;
  padding: 2px 6px;
  top: 0;
  right: 0;
  z-index: 22;
  display: flex;
  align-items: center;
  gap: 6px;
}
.stutas-user span {
  border-radius: 100%;
  background: #5abc7a;
  aspect-ratio: 1;
  width: 10px;
  flex-shrink: 0;
  height: 10px;
  display: inline-block;
}
.stutas-user p {
  line-height: 11px;
  font-size: 10px;
  color: #5abc7a;
}

.u-name h4 {
  font-size: 14px;
  color: var(--secondary);
  font-weight: 300;
}
.u-name button {
  font-size: 14px;
  border: none;
  background: none;
  color: var(----secondary);
}

.per-btn {
  background: var(--white);
  border: 1px solid var(--secondary);
  padding: 6px 0;
  text-align: center;
  width: 100%;
  font-size: 14px;
}
.per-btn:hover {
  background: var(--black);
  color: var(--white);
}
.new-line-border {
  margin-block: 3px;
  border-top: 1px solid #999;
  border-bottom: none;
}
.user-exp-date p {
  font-size: 14px;
  color: var(--secondary);
  font-weight: 300;
}
.user-exp-date b {
  font-weight: 600;
}

.des-box .des-heading {
  font-weight: 600;
  color: var(--black);
  font-size: 18px;
}
.innerdes-content textarea {
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  text-align: left;
}

.button-des button {
  font-size: 14px;
  padding: 8px 20px;
  width: 100%;
  border-radius: 4px;
}
.button-des .btn-white {
  background: var(--white);
  color: var(--black);
  border: 1px solid #ccc;
}
.button-des .btn-white:hover {
  background: var(--secondary);
  color: var(--white);
  border: 1px solid var(--secondary);
}
.button-des .btn-green {
  background: #5abc7a;
  color: var(--white);
  border: 1px solid #47a064;
}
.button-des .btn-green:hover {
  background: var(--secondary);
  color: var(--white);
  border: 1px solid var(--secondary);
}
.input-inline-block :is(input[type="text"], select) {
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  width: 100%;
  height: 40px;
  background: var(--white);
}

.last-botm-text h4 {
  color: #999;
  font-size: 14px;
}
.last-botm-text b {
  font-weight: 600;
  color: var(--black);
}
.innerdes-content {
  background-color: rgb(245, 245, 245);
}

.srcl {
  display: flex;
  margin: auto;
  width: 1300px;
  margin: 10px auto;

  justify-content: space-between;
}

.srcl div,
.srcl a {
  padding: 8px 4px;
  position: relative;
  flex-wrap: nowrap;
  margin-right: 5px;
  white-space: pre;
}

.slide-sample {
  margin: 0 20px;
  position: relative;
  display: table;
  width: 100%;
  overflow-x: hidden;
}

.slideouter {
  width: 100%;
  overflow-x: scroll;
  margin: auto;
  display: flex;
  scrollbar-width: none;
}

.preSlide {
  left: -23px;
  position: absolute;
  top: 0px;
  font-size: 27px;
  color: #999;
}

.nextSlide {
  right: -24px;
  position: absolute;
  top: 0px;
  font-size: 27px;
  color: #999;
}
.slideinner {
  margin: 0;
}
.workspace {
  background: #cce1f4;
}
.workspace .work-block {
  padding: 30px 15px 90px 15px;
}
.workspace .work-block h3 {
  font-size: 20px;
  text-transform: capitalize;
}
.workspace .work-block p {
  font-size: 14px;
  font-weight: 300;
}

.workspace .row [class*="col-"]:first-child .work-block {
  background: #282841 url(./images/imgwork.jpg) no-repeat right bottom;
  background-size: 200px;
  color: var(--white);
}
.workspace .row [class*="col-"]:nth-child(2) .work-block {
  background: #32beeb url(./images/imgjob.png) no-repeat right bottom;
  background-size: 80px;
  color: var(--white);
}
.workspace .row [class*="col-"]:nth-child(3) .work-block {
  background: #5e9dd7 url(./images/imgjob.png) no-repeat right bottom;
  background-size: 80px;
  color: var(--white);
}
.workspace .row [class*="col-"]:nth-child(4) .work-block {
  background: #5fa6bc url(./images/imgjob.png) no-repeat right bottom;
  background-size: 80px;
  color: var(--white);
}
.notifi-title h3 {
  font-size: 26px;
  font-weight: 600;
  color: var(--black);
}
.notifi-title p {
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
}
.noti-block {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px 15px;
  height: 120px;
  margin: 15px 0;
}
.noti-block h4 {
  color: #999;
  font-size: 12px;
}
.noti-block h2 {
  font-size: 16px;
  color: var(--black);
  text-decoration: underline;
}
.noti-block .img-noti-icon {
  font-size: 30px;
}
.noti-block .fa-check-circle {
  color: green;
}

.red-btn-primary {
  background: var(--primary);
  color: var(--white);
  font-size: 16px;
  display: inline-block;
  padding: 10px 30px;
  border: none;
  width: 280px;
  text-align: center;
}
.red-btn-primary:hover {
  background: var(--secondary);
  color: var(--white);
}
.workspace-block {
  background: #3280c2 url(./images/s5.jpg) no-repeat center;
  background-size: cover;
  height: 100vh;
}
.ava-box {
  background: rgba(0, 0, 0, 0.5);
  padding: 60px 30px;
}
.ava-box label {
  color: var(--white);
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
}
.ava-box input {
  background: transparent !important;
  position: relative;
  z-index: 4;
}
.ava-box .icon-cal-ab i {
  position: absolute;
  top: 10px;
  z-index: 2;
  right: 10px;
}

.bell-notif .drop-noti {
  position: absolute;
  min-width: 300px;
  right: 0;
  z-index: 34;
  background: var(--white);
  padding: 6px 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
  display: none;
}
.bell-notif .drop-noti ul {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.bell-notif .drop-noti ul li:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.bell-notif .drop-noti ul li a {
  padding: 4px 10px;
  display: block;
  font-size: 12px;
  color: var(--black);
}
.bell-notif .drop-noti ul li:hover {
  color: var(--secondary);
}
.bell-notif:hover .drop-noti {
  display: block;
}

.close-btn-right {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 54;
}
.cong-block h3 {
  color: var(--primary);
  font-weight: 800;
  font-size: 32px;
}
.cong-block p {
  font-size: 16px;
  color: var(--black);
}
.cong-block p a {
  color: var(--black);
  text-decoration: underline;
}
.acc-btns a {
  padding: 10px 30px;
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
  color: var(--white);
}

.acc-btns .btnd {
  background: #fe0c0c;
}
.acc-btns .btna {
  background: #56b254;
}
.letter-noti p {
  font-size: 12px;
  color: var(--black);
  text-align: left;
}
@media (max-width: 768px) {
}
@media (max-width: 767px) {
  .user-login-btn a {
    font-size: 13px;
    padding: 6px 15px;
  }
  .dicover-block h1 {
    font-size: 32px;
  }
  .dicover-block .search-tags h4,
  .dicover-block .search-tags a {
    font-size: 12px;
  }
  .hlogo-box {
    width: 130px;
  }
  .workspace-block {
    height: 800px;
  }
  .user-login-btn .reg-btn {
    padding: 6px 15px;
    font-size: 13px;
  }
}
