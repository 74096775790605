.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu p span {
  font-size: 12px;
  font-weight: 500;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  color: black;
}

.introdu h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.introdu ul li::marker {
  color: var(--primary);
}
.introdu h6 {
  font-size: 29px;
  font-weight: 400;
  line-height: 41px;
}

.introdu .no {
  width: 40px;
  height: 40px;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.introdu .no p {
  color: #fff;
}
.silder-box1 .ss1-btn {
  background-color: var(--primary);
  padding: 6px 50px !important;
  border-radius: 30px;
}
.ss1-btn p {
  color: #fff;
}
.silder-box2 .steps a {
  background: var(--primary);
  width: fit-content;
  padding: 5px 30px;
  border-radius: 10px;
  display: block;
  font-size: 20px;
  font-weight: 800;
  color: white;
}
.silder-box2 .steps {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.intro .container-xxl {
  overflow-x: hidden;
}
.human .container-xxl {
  overflow-x: hidden;
}
.nav-tabs .nav-link.active {
  background: #fff !important;
  display: block;
  color: var(--primary) !important;
}
.comp-bg {
  background-image: url(images/truck.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: fit-content;
}
.comp-bg .overlay-bg {
  background-color: rgba(37, 49, 67, 0.655);
}

.comp-bg .overlay-bg h6 {
  font-size: 30px;
  line-height: 46px;
  color: #fff;
  font-weight: 600;
}
.comp-bg .overlay-bg h6 span {
  color: var(--primary);
}
.border-top {
  width: 100px;
  height: 5px;
  background-color: white;
}
.bg-red {
  background-color: var(--primary);
}
.bg-red .red p {
  font-size: 20px;
  line-height: 35px;
  color: #fff;
}
@media (max-width: 500px) {
  .comp-bg .overlay-bg h6 {
    font-size: 16px;
    line-height: 30px;
    color: #fff;
  }
  .comp-bg .overlay-bg {
    background-color: rgba(45, 56, 61, 0.77);
  }
}
.acc-img1 {
  height: 500px;
}
.acc-img1 img {
  height: 100%;
  object-fit: contain;
}
.cart-2 .nav-tabs .nav-item.show .nav-link,
.cart-2 .nav-tabs .nav-link.active {
  border: none;
  background-color: transparent;
  border-color: transparent;
}
.cart-2 .nav-tabs .nav-link:focus,
.cart-2 .nav-tabs .nav-link:hover,
.cart-2 .nav-tabs .nav-link:focus-visible {
  /* isolation: isolate; */
  border: none;
  outline: none;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.cart-1.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent;
}
button#nav-home-tab12 {
  padding: 27px 0px;
  background-color: #efefef;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
}
button#nav-home-tab12:focus {
  background-color: #ffff;
  color: var(--primary);
}
button#nav-profile-tab22 {
  padding: 27px 0px;
  background-color: #efefef;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
}
button#nav-profile-tab22:focus {
  background-color: #ffff;

  color: var(--primary);
}
button#nav-contact-tab {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: black;
  padding: 27px 0px;
  background-color: #efefef;
}
button#nav-contact-tab:focus {
  background-color: #ffff;
  color: var(--primary);
}
.scene {
  display: inline-block;
  width: 246px;
  height: 264px;
  margin: 40px 0;
  perspective: 600px;
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 1s;
}

.card.is-flipped {
  transform: translateX(-100%) rotateY(-180deg);
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 35px;
  font-weight: 400;
  backface-visibility: hidden;
}

.card__face--back {
  color: #000;
  /* transform: rotateY(180deg); */
}
button.btn.btn-secondary {
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
button.btn.btn-secondary i {
  font-size: 14px;
}
