.introdu p {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.introdu label {
  font-size: 17px;
  line-height: 33px;
  font-weight: 400;
  color: black;
}
.br-top {
  border-top: 2px solid #edecec;
}

.introdu h5 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: black;
  padding: 10px 0px;
}
.introdu h3 {
  font-size: 17px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.human .no1 p {
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.human .no1 {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  height: 40px;
}
.environmental {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #edecec;
}
.environmental h3 {
  background-color: var(--primary);
  text-align: center;
}
.intro,
.human {
  overflow: hidden;
}
.pie .pie-text-1 {
  position: absolute;
  top: 39%;
  right: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transform: translateX(50%);
}
.pie .pie-text-1 i {
  font-size: 78px;
  color: var(--primary);
}
.pie .refresh {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}
.pie .refresh i {
  font-size: 30px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .d-width {
    overflow-x: auto;
  }
  .width {
    width: 690px;
  }
  .quiz-result svg {
    width: 305px;
  }
  .quiz-result .pie .percentage {
    position: absolute;
    top: 10%;
    right: 16%;
  }
}
