input,
textarea,
select,
ol,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-decoration: none;
  outline: none;
  list-style: none;
  padding: 0;
}

a,
a:hover,
a:focus,
*:focus-visible {
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-decoration: none;
  outline: none;
}

button:focus {
  outline: none;
  border: none;
}

button:focus,
input:focus {
  box-shadow: none !important;
}

button {
  cursor: pointer;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}
