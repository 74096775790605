$primary: rgba(70, 155, 255, 1);
$blue:#2B3767;
$btn_color: linear-gradient(90deg, #469bff 0%, #2a5d99 100%);
$white: #fff;
$black: #000;
$font: "Archivo", sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

//! media queries
@mixin xl {
  @media only screen and (max-width: 1399.98px) {
    @content;
  }
}
@mixin lg {
  @media only screen and (max-width: 1199.98px) {
    @content;
  }
}
@mixin md {
  @media only screen and (max-width: 991.98px) {
    @content;
  }
}
@mixin sm {
  @media only screen and (max-width: 767.98px) {
    @content;
  }
}
@mixin xs {
  @media only screen and (max-width: 575.98px) {
    @content;
  }
}
@mixin cw($value) {
  @media only screen and (max-width: $value) {
    @content;
  }
}
