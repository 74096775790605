@import "../css/all.min.css";
@import "../css/slick.css";
input,
textarea,
select,
ol,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-decoration: none;
  outline: none;
}
a,
a:hover,
a:focus,
*:focus-visible {
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-decoration: none;
  outline: none;
}
button:focus {
  outline: none;
  border: none;
}
button:focus,
input:focus {
  box-shadow: none !important;
}
button {
  cursor: pointer;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ls li {
  list-style: none;
}
.cur {
  cursor: pointer;
}
.po img {
  opacity: 0;
}
.vclip {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.maxw img {
  max-width: 100%;
}
.g-center {
  place-items: center;
  display: grid;
}
.fulw img {
  width: 100%;
}

.rel {
  position: relative;
}
.ofit img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
